<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="800px">
      <v-card light :disabled="disabled">
        <v-card-title style="background-color: #f5f5f5">
          <span>Neuer Termin</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row style="padding-top: 10px;">
              <v-col cols="12">
                <v-select
                  label="Termintyp"
                  v-model="termintyp"
                  :items="termintypes"
                >
                </v-select>
              </v-col>
            </v-row>

            <div v-if="termintyp == 'Behandlung'">
              <v-row>
                <v-col cols="9" style="padding-bottom: 0px;">
                  <v-autocomplete
                    label="Patient"
                    v-model="patient"
                    :loading="loading"
                    :search-input.sync="search_term"
                    :items="patienten"
                    placeholder="Tippen um eine Patientensuche zu starten"
                    prepend-icon="mdi-account"
                    :item-text="patient_text"
                    item-value="id"
                    hide-no-data
                    return-object
                    :disabled="patient_neu_flag"
                    :error-messages="patientErrors"
                    @input="v$.patient.$touch()"
                    @blur="v$.patient.$touch()"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.name + ', ' + item.vorname"
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                          {{item.kt_typ_name}} - geb. {{item.geburtsdatum | formatDate}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col>
                  <v-checkbox
                    class=""
                    label="Neuer Patient?"
                    v-model="patient_neu_flag"
                    @change="clearPatientInput"
                    :hide-details="true"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-divider class="my-5" v-if="patient_neu_flag"></v-divider>
              <v-row v-if="patient_neu_flag">
                <v-col cols="6">
                  <v-text-field
                    class="mt-0 pt-0 required"
                    v-model="patient_name"
                    label="Name"
                    prepend-icon="mdi-card-account-details"
                    :error-messages="patientNameErrors"
                    @input="v$.patient_name.$touch()"
                    @blur="v$.patient_name.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="mt-0 pt-0 required"
                    v-model="patient_vorname"
                    label="Vorname"
                    prepend-icon="mdi-card-account-details-outline"
                    :error-messages="patientVornameErrors"
                    @input="v$.patient_vorname.$touch()"
                    @blur="v$.patient_vorname.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="mt-0 pt-0 required"
                    v-model="patient_telefon"
                    label="Telefonnummer"
                    type="tel"
                    prepend-icon="mdi-phone"
                    :error-messages="patientTelefonErrors"
                    @input="v$.patient_telefon.$touch()"
                    @blur="v$.patient_telefon.$touch()"
                    v-mask="[...masks.telephone]"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-menu
                    v-model="menu.patient_geburtsdatum"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="patient_geburtsdatum"
                        label="Geburtsdatum"
                        prepend-icon="mdi-cake-variant"
                        v-model="selections.patient_geburtsdatum"
                        v-on="on"
                        class="mt-0 pt-0 required"
                        :error-messages="patientGeburtsdatumErrors"
                        @input="handleInputDateText('patient_geburtsdatum')"
                        @focus="handleFocusDateText('patient_geburtsdatum')"
                        placeholder="TT.MM.JJJJ"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="patient_geburtsdatum"
                      :active-picker.sync="geburtsdatum_picker"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1910-01-01"
                      @input="handleInputDatePicker('patient_geburtsdatum')"
                      first-day-of-week="1"
                      locale="de-de"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-divider
                style="padding-bottom: 2em;"
                v-if="patient_neu_flag"
              ></v-divider>

              <v-row>
                <v-col cols="3" style="padding-top: 0px;">
                  <v-select
                    label="Heilmitteltyp"
                    v-model="treatmentType"
                    :items="treatmentTypes"
                    prepend-icon="mdi-hand-heart"
                    :disabled="isHeilmitteltypDisabled"
                  >
                  </v-select>
                </v-col>

                <v-col cols="8" style="padding-top: 0px;">
                  <v-autocomplete
                    ref="heilmittel"
                    label="Heilmittel"
                    v-model="heilmittel"
                    :items="heilmittelList"
                    placeholder="Heilmittel auswählen"
                    :item-text="heilmittel_text"
                    :disabled="!patient_neu_flag && !patient"
                    item-value="id"
                    return-object
                    hide-no-data
                    style="border-radius: 0"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col class="pl-0" cols="1">
                  <v-btn
                    elevation="2"
                    color="primary"
                    fab
                    small
                    :disabled="!heilmittel || !isRemovable(heilmittel)"
                    @click="addHeilmittelAndResetInput"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="12" class="pt-0 pb-5">
                  <v-data-table
                    dense
                    :headers="behandlungen_headers"
                    :items="behandlungen"
                    :items-per-page="10"
                    class="elevation-1"
                    :hide-default-footer="true"
                    item-key="key"
                  >
                    <template v-slot:item.anzahl="props">
                      <v-btn
                        @click="openEditAnzahlDialog(props.item)"
                        small
                        text
                        style="justify-content: right; width: 70px;"
                        :disabled="anzahlDisabled(props.item)"
                        >{{ props.item.anzahl }}
                        <v-icon right x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:item.zeiteinheit="props">
                      <v-btn
                        @click="openEditZeiteinheitDialog(props.item)"
                        small
                        text
                        style="justify-content: right; width: 70px;"
                        :disabled="!isRemovable(props.item)"
                        >{{ props.item.zeiteinheit }}
                        <v-icon right x-small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>

                    <template slot="no-data">
                      <div :class="{ error_entry: v$.behandlungen.$error }">
                        Keine Heilmittel ausgewählt
                      </div>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        @click="deleteHeilmittelItem(item)"
                        :disabled="!isRemovable(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                  <div
                    v-if="unusedTreatmentErrors.length != 0"
                    class="v-messages theme--light error--text pt-2 pl-2"
                    role="alert"
                  >
                    <div class="v-messages__wrapper">
                      <div class="v-messages__message">
                        {{ unusedTreatmentErrors[0] }}
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-select
                    label="Terminauswahl"
                    v-model="terminmode"
                    :items="terminmodes"
                    prepend-icon="mdi-calendar"
                  >
                  </v-select>
                </v-col>

                <SeriesCreator
                  v-if="terminmode == 'Serientermin'"
                ></SeriesCreator>

                <v-col cols="12" class="pt-0">
                  <v-data-table
                    dense
                    :headers="termine_headers"
                    :items="termine"
                    :items-per-page.sync="itemsPerPage"
                    fixed-header
                    :height="
                      itemsPerPage === -1 && termine.length > 10
                        ? 32 * 11 - 1
                        : undefined
                    "
                    :single-expand="true"
                    show-expand
                    class="elevation-1"
                    :hide-default-footer="true"
                    item-key="key"
                    :item-class="row_classes"
                    @click:row="rowClicked"
                  >
                    <template v-slot:item.mitarbeiter_str="props">
                      <v-edit-dialog :return-value="props.item.mitarbeiter_str"
                        >{{ props.item.mitarbeiter_str }}
                        <template v-slot:input>
                          <v-autocomplete
                            label="Mitarbeiter-Auswahl"
                            v-model="props.item.mitarbeiter"
                            :items="getAvailableMitarbeiterByTermin(props.item)"
                            placeholder="Mitarbeiter auswählen"
                            :item-text="mitarbeiter_text"
                            item-value="id"
                            return-object
                            hide-no-data
                            :disabled="
                              getAvailableMitarbeiterByTermin(props.item)
                                .length == 0
                            "
                            @change="setMitarbeiterStr(props.item)"
                          >
                            >
                          </v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template slot="no-data">
                      <div :class="{ error_entry: v$.termine.$error }">
                        Keine Termine ausgewählt
                      </div>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        {{ behandlungenSummary(item) }}
                      </td>
                    </template>

                    <template v-slot:item.start_date_str="{ item }">
                      <span class="weekday">{{
                        item.start_date_str | formatDateWeekday
                      }}</span>
                      <span>{{ item.start_date_str }}</span>
                    </template>

                    <template v-slot:item.id="{ item }">
                      {{ -1 * item.id }}
                    </template>

                    <template v-slot:item.valid="{ item }">
                      <v-icon v-if="item.valid" small color="green">
                        mdi-check-circle
                      </v-icon>

                      <v-tooltip v-if="!item.valid" bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small color="red" v-bind="attrs" v-on="on">
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span style="white-space: pre-line">{{
                          item.error_message
                        }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        class="mx-1"
                        small
                        @click="handleRepick(item)"
                      >
                        mdi-pencil
                      </v-icon>

                      <v-icon
                        class="mx-1"
                        small
                        @click="deleteTerminItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>

                <v-col
                  style="
                    text-align: right;
                    padding-top: 0px;
                    margin-bottom: -40px;
                  "
                >
                  <v-fab-transition>
                    <v-btn
                      style="top: -2rem; left: 0.3rem; z-index: 100;"
                      elevation="2"
                      color="primary"
                      fab
                      small
                      :disabled="fabAddButtonDisabled"
                      @click="hideAddTerminShowSelectHeilmittel"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Bemerkung"
                    v-model="bemerkung"
                    rows="1"
                    prepend-icon="mdi-comment"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-dialog v-model="anzahlDialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="headline">
                    Anzahl bearbeiten
                  </v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="editAnzahl"
                      label="Anzahl"
                      type="text"
                      min="1"
                      max="100"
                      inputmode="numeric"
                      class="required"
                      @input="v$.editAnzahl.$touch()"
                      @blur="v$.editAnzahl.$touch()"
                      :error-messages="anzahlErrors"
                      v-mask="countMask"
                      v-on:keyup.enter="saveAnzahl"
                      autofocus
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="closeEditAnzahlDialog"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      class="primary-nav-btn" 
                      color="primary"
                      text
                      :disabled="anzahlErrors.length != 0 || (currentTreatmentItem && editAnzahl == currentTreatmentItem.anzahl)"
                      @click="saveAnzahl"
                    >
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dauerDialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="headline">
                    Dauer bearbeiten
                  </v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="editDauer"
                      label="Dauer (min)"
                      type="text"
                      min="0"
                      max="120"
                      inputmode="numeric"
                      class="required"
                      @input="v$.editDauer.$touch()"
                      @blur="v$.editDauer.$touch()"
                      :error-messages="dauerErrors"
                      v-mask="'###'"
                      suffix="min"
                      v-on:keyup.enter="saveZeiteinheit"
                      autofocus
                    ></v-text-field>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="closeEditZeiteinheitDialog"
                    >
                      Abbrechen
                    </v-btn>
                    <v-btn
                      class="primary-nav-btn" 
                      color="primary"
                      text
                      :disabled="dauerErrors.length != 0 || (currentTreatmentItem && editDauer == currentTreatmentItem.zeiteinheit)"
                      @click="saveZeiteinheit"
                    >
                      Speichern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <div v-if="termintyp == 'Intern'">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="titel"
                    label="Titel"
                    prepend-icon="mdi-format-title"
                    class="required"
                    :error-messages="titleErrors"
                    @input="v$.titel.$touch()"
                    @blur="v$.titel.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Bemerkung"
                    v-model="bemerkung"
                    rows="1"
                    prepend-icon="mdi-comment"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    label="Ganztags?"
                    v-model="ganztag"
                    @change="clearTimeInput"
                  ></v-checkbox>
                </v-col>

                <v-col cols="6">
                  <v-menu
                    v-model="menu.start_date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="startdatum"
                        label="Startdatum"
                        prepend-icon="mdi-calendar"
                        v-model="selections.start_date"
                        v-on="on"
                        class="required"
                        :error-messages="startdateErrors"
                        @input="handleInputDateText('start_date')"
                        @focus="handleFocusDateText('start_date')"
                        @blur="v$.start_date.$touch()"
                        placeholder="TT.MM.JJJJ"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="start_date"
                      @input="handleInputDatePicker('start_date')"
                      first-day-of-week="1"
                      locale="de-de"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    ref="startzeit"
                    v-model="start_time"
                    prepend-icon="mdi-clock-outline"
                    label="Startzeit"
                    type="time"
                    :disabled="ganztag"
                    :error-messages="starttimeErrors"
                    @input="v$.start_time.$touch()"
                    @blur="v$.start_time.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-menu
                    v-model="menu.end_date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="enddatum"
                        label="Enddatum"
                        prepend-icon="mdi-calendar"
                        v-model="selections.end_date"
                        v-on="on"
                        class="required"
                        :error-messages="enddateErrors"
                        @input="handleInputDateText('end_date')"
                        @focus="handleFocusDateText('end_date')"
                        @blur="v$.end_date.$touch()"
                        placeholder="TT.MM.JJJJ"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="end_date"
                      @input="handleInputDatePicker('end_date')"
                      first-day-of-week="1"
                      locale="de-de"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    ref="endzeit"
                    v-model="end_time"
                    prepend-icon="mdi-clock-outline"
                    label="Endzeit"
                    type="time"
                    :disabled="ganztag"
                    :error-messages="endtimeErrors"
                    @input="v$.end_time.$touch()"
                    @blur="v$.end_time.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    ref="mitarbeiter"
                    label="Mitarbeiter"
                    v-model="mitarbeiter"
                    :items="mitarbeiterList"
                    placeholder="Mitarbeiter auswählen"
                    prepend-icon="mdi-doctor"
                    :item-text="mitarbeiter_text"
                    class="required"
                    item-value="id"
                    return-object
                    hide-no-data
                    clearable
                    @change="setMitarbeiterColor"
                    :error-messages="mitarbeiterErrors"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="8">
                  <v-dialog v-model="color_dialog" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Farbmarkierung"
                        v-model="color.hexa"
                        prepend-icon="mdi-palette"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        :color="color.hexa"
                      ></v-text-field>
                    </template>

                    <v-card>
                      <v-color-picker
                        v-model="color"
                        dot-size="10"
                        show-swatches
                        swatches-max-height="100"
                        hide-inputs
                      >
                      </v-color-picker>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="primary-nav-btn" 
                          text
                          color="primary"
                          @click="color_dialog = false"
                        >
                          Ok
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    solo
                    readonly
                    disabled
                    :background-color="color.hexa"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeWindow"> Zurück </v-btn>
          <v-btn class="primary-nav-btn" color="primary" text @click="create_Termin"> Speichern </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" persistent max-width="650px">
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Termin Validierung</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeErrorDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <span
                  >Die folgenden Termine konnten nicht validiert werden:</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(value, key) in errorData"
                    :key="key"
                  >
                    <v-expansion-panel-header>
                      <b>{{ value.name }}</b
                      >{{ value.start }} - {{ value.end }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ul>
                        <li v-for="(error, index) in value.errors" :key="index">
                          {{ error }}
                        </li>
                      </ul>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary-nav-btn" color="primary" text @click="closeErrorDialog"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapMutations, mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { requiredUnless } from "@vuelidate/validators";
import SeriesCreator from "./SeriesCreator.vue";

const defaultMenuData = () => ({
  start_date: false,
  end_date: false,
  patient_geburtsdatum: false,
});

const defaultSelectionsData = () => ({
  start_date: null,
  end_date: null,
  patient_geburtsdatum: null,
});

const isEmptyListOrNull = (list) => {
  return list == null || list.length == 0;
};

function startDateIsBeforeEndDate(date) {
  if (this.termintyp == "Intern" && date && this.end_date) {
    return moment(date).isSameOrBefore(moment(this.end_date));
  }
  return true;
}

function startDateTimeIsBeforeEndDateTime(time) {
  // check if start date and end date are present
  if (
    this.termintyp == "Intern" &&
    this.start_date &&
    this.end_date &&
    time &&
    this.end_time
  ) {
    let start = moment(this.start_date + " " + time, "YYYY-MM-DD HH:mm");
    let end = moment(this.end_date + " " + this.end_time, "YYYY-MM-DD HH:mm");
    return start.isBefore(end);
  }
  return true;
}

function isValidAnzahl(value) {
  if (this.termintyp == "Behandlung" && this.currentTreatmentItem) {
    let in_use = this.$store.getters["overlays/behandlungenInUse"](
      this.currentTreatmentItem
    );
    return Number(value) >= in_use;
  }
  return true;
}

function isInRange(min, max) {
  return function(value) {
    if (this.termintyp == "Behandlung" && this.currentTreatmentItem) {
      return value >= min && value <= max;
    }
    return true;
  };
}

export default {
  name: "TerminAdd",
  props: ["switchToTermin"],
  components: {
    SeriesCreator,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations: {
    patient: {
      required: requiredUnless(function() {
        return this.patient_neu_flag || this.termintyp != "Behandlung";
      }),
    },
    patient_name: {
      required: requiredUnless(function() {
        return !this.patient_neu_flag || this.termintyp != "Behandlung";
      }),
    },
    patient_vorname: {
      required: requiredUnless(function() {
        return !this.patient_neu_flag || this.termintyp != "Behandlung";
      }),
    },
    patient_geburtsdatum: {
      required: requiredUnless(function() {
        return !this.patient_neu_flag || this.termintyp != "Behandlung";
      }),
    },

    patient_telefon: {
      required: requiredUnless(function() {
        return !this.patient_neu_flag || this.termintyp != "Behandlung";
      }),
    },

    behandlungen: {
      required: requiredUnless(function() {
        return this.termintyp != "Behandlung";
      }),
    },

    verbleibende_behandlungen: {
      isEmptyListOrNull: function() {
        if (this.termintyp != "Behandlung") {
          return true;
        }
        return isEmptyListOrNull(this.verbleibende_behandlungen);
      },
    },

    termine: {
      required: requiredUnless(function() {
        return this.termintyp != "Behandlung";
      }),
    },

    titel: {
      required: requiredUnless(function() {
        return this.termintyp == "Behandlung";
      }),
    },

    start_date: {
      required: requiredUnless(function() {
        return this.termintyp == "Behandlung";
      }),
      startDateIsBeforeEndDate,
    },

    start_time: {
      required: requiredUnless(function() {
        return this.ganztag || this.termintyp == "Behandlung";
      }),
      startDateTimeIsBeforeEndDateTime,
    },

    end_date: {
      required: requiredUnless(function() {
        return this.termintyp == "Behandlung";
      }),
    },

    end_time: {
      required: requiredUnless(function() {
        return this.ganztag || this.termintyp == "Behandlung";
      }),
    },

    mitarbeiter: {
      required: requiredUnless(function() {
        return this.termintyp == "Behandlung";
      }),
    },

    editAnzahl: {
      required: requiredUnless(function() {
        return this.termintyp != "Behandlung" || !this.currentTreatmentItem;
      }),
      isValidAnzahl,
      isInRange: isInRange(1, 100),
    },

    editDauer: {
      required: requiredUnless(function() {
        return this.termintyp != "Behandlung" || !this.currentTreatmentItem;
      }),
      isInRange: isInRange(0, 120),
    },
  },

  filters: {

    formatDate(value) {
      return value ? moment(String(value)).format("DD.MM.YYYY") : "";
    },

    formatDateWeekday(value) {
      if (!value) return "";
      moment.locale("de");
      return moment(String(value), "DD.MM.YYYY").format("ddd");
    },
  },

  data() {
    return {
      loading: false,
      search_term: "",
      geburtsdatum_picker: "YEAR",
      itemsPerPage: -1,

      errorDialog: false,
      errorData: null,

      currentTreatmentItem: null,
      anzahlDialog: false,
      editAnzahl: null,
      dauerDialog: false,
      editDauer: null,

      masks: {
        telephone: ["###############"],
      },

      menu: defaultMenuData(),

      selections: defaultSelectionsData(),

      color_dialog: false,
      behandlungen_headers: [
        {
          text: "Bezeichnung",
          align: "start",

          value: "bezeichnung",
        },
        { text: "Anzahl", value: "anzahl" },
        { text: "Dauer", value: "zeiteinheit" },
        { text: "Aktionen", value: "actions", sortable: false },
      ],
      termine_headers: [
        {
          text: "Nr.",
          align: "start",
          value: "id",
          sortable: false,
        },
        {
          text: "Datum",
          value: "start_date_str",
          sortable: false,
        },
        {
          text: "Zeit",
          value: "start_time",
          sortable: false,
        },
        {
          text: "Mitarbeiter",
          value: "mitarbeiter_str",
          sortable: false,
        },

        {
          text: "Status",
          value: "valid",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
        { text: "Aktionen", value: "actions", sortable: false },
      ],

      countMask: {
        mask: "PNN",
        tokens: {
          P: {
            pattern: /[1-9]/,
          },
          N: {
            pattern: /[0-9]/,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("mitarbeiter", ["getAvailableMitarbeiterByTermin"]),

    verbleibende_behandlungen() {
      return this.$store.getters["overlays/addGetRemainingBehandlungen"];
    },

    // errors
    patientErrors() {
      const errors = [];

      if (this.v$ && !this.patient_neu_flag) {
        const entry = this.v$.patient;

        if (!entry.$dirty) return errors;

        // required
        entry.required.$invalid &&
          errors.push("Es wurde kein Patient ausgewählt!");
      }

      return errors;
    },

    unusedTreatmentErrors() {
      const errors = [];

      if (this.v$) {
        const entry = this.v$.verbleibende_behandlungen;

        if (!entry.$dirty) return errors;

        // This should only be invalid if there are still unused treatments left or
        // if the appointment type is not for a treatment but an internal appointment
        entry.isEmptyListOrNull.$invalid &&
          errors.push("Nicht alle Behandlungen wurden eingeplant!");
      }

      return errors;
    },

    patientVornameErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient_vorname;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Vorname ist leer!");
      }

      return errors;
    },

    patientNameErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient_name;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Nachname ist leer!");
      }

      return errors;
    },

    patientGeburtsdatumErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient_geburtsdatum;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Geburtsdatum ist leer!");
      }

      return errors;
    },

    patientTelefonErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.patient_telefon;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Telefonnummer ist leer!");
      }

      return errors;
    },

    startdateErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.start_date;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Startdatum ist leer!");
        entry.startDateIsBeforeEndDate.$invalid &&
          errors.push("Startdatum muss vor dem Enddatum liegen!");
      }

      return errors;
    },

    starttimeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.start_time;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Startzeit ist leer!");
        entry.startDateTimeIsBeforeEndDateTime.$invalid &&
          errors.push("Startzeit muss vor der Endzeit liegen!");
      }

      return errors;
    },

    endtimeErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.end_time;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Endzeit ist leer!");
      }

      return errors;
    },

    enddateErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.end_date;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Enddatum ist leer!");
      }

      return errors;
    },

    titleErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.titel;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Titel ist leer!");
      }

      return errors;
    },

    mitarbeiterErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.mitarbeiter;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Mitarbeiter ist leer!");
      }

      return errors;
    },

    anzahlErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.editAnzahl;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Keine Anzahl angegeben!");
        entry.isInRange.$invalid &&
          errors.push("Anzahl muss zwischen 1 und 100 liegen!");
        entry.isValidAnzahl.$invalid &&
          errors.push("Eine größere Anzahl ist bereits in Verwendung!");
      }
      return errors;
    },

    dauerErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.editDauer;

        if (!entry.$dirty) return errors;

        entry.required.$invalid && errors.push("Keine Dauer angegeben!");
        entry.isInRange.$invalid &&
          errors.push("Dauer muss zwischen 0 und 120 min liegen!");
      }
      return errors;
    },

    active() {
      return this.$store.getters["overlays/addIsVisible"];
    },

    termintypes() {
      return this.$store.getters["overlays/getTerminTypes"];
    },

    terminmodes() {
      return this.$store.getters["overlays/getTerminModes"];
    },

    treatmentTypes() {
      if (!this.patient && !this.patient_neu_flag) return [];

      const leTyp = this.$store.getters["kunde/getKunde"].le_typ;
      const ktTyp = this.patient ? this.patient.kt_typ : null;
      return this.$store.getters["overlays/getTreatmentTypes"](ktTyp, leTyp);
    },

    patienten() {
      return this.$store.getters["patienten/getPatienten"];
    },

    mitarbeiterList() {
      return this.$store.getters["mitarbeiter/getMitarbeiter"];
    },

    isHeilmitteltypDisabled() {
      return this.treatmentTypes.length <= 1;
    },

    heilmittelList() {
      let entries = [];

      if (["Standard", "Blanko"].includes(this.treatmentType)) {
        entries = this.$store.getters["zeiteinheiten/getZeiteinheiten"];
      } else {
        entries = this.$store.getters[
          "zeiteinheiten/getVollprivatZeiteinheiten"
        ];
      }
      return entries;
    },

    fabAddButtonDisabled() {
      const emptyTreatment = this.behandlungen.length === 0;
      const noRemainingTreatments = this.verbleibende_behandlungen.length === 0;
      const isSeries = this.terminmode === "Serientermin";
      const noAppointmentSelected = this.termine.length === 0;
      const noPatientSelected = this.patient_neu_flag
        ? !this.patient_name ||
          !this.patient_vorname ||
          !this.patient_telefon ||
          !this.patient_geburtsdatum
        : !this.patient;

      return (
        emptyTreatment ||
        noRemainingTreatments ||
        (isSeries && noAppointmentSelected) ||
        noPatientSelected
      );
    },

    disabled: {
      get() {
        return this.$store.state.overlays.add.disabled;
      },

      set(value) {
        this.$store.commit("overlays/updateAddDisabled", value, { root: true });
      },
    },

    start_date: {
      get() {
        return this.$store.state.overlays.add.selected.start_date;
      },

      set(value) {
        this.$store.commit("overlays/updateStartDate", value, { root: true });
      },
    },

    end_date: {
      get() {
        return this.$store.state.overlays.add.selected.end_date;
      },

      set(value) {
        this.$store.commit("overlays/updateEndDate", value, { root: true });
      },
    },

    start_time: {
      get() {
        return this.$store.state.overlays.add.selected.start_time;
      },

      set(value) {
        this.$store.commit("overlays/updateStartTime", value, { root: true });
      },
    },

    end_time: {
      get() {
        return this.$store.state.overlays.add.selected.end_time;
      },

      set(value) {
        this.$store.commit("overlays/updateEndTime", value, { root: true });
      },
    },

    termintyp: {
      get() {
        return this.$store.state.overlays.add.selected.termintyp;
      },

      set(value) {
        this.$store.commit("overlays/updateTermintyp", value, { root: true });
      },
    },

    terminmode: {
      get() {
        return this.$store.state.overlays.add.selected.terminmode;
      },

      set(value) {
        this.$store.commit("overlays/updateTerminmode", value, { root: true });
      },
    },

    treatmentType: {
      get() {
        return this.$store.state.overlays.add.selected.treatmentType;
      },

      set(value) {
        this.$store.commit("overlays/updateTreatmentType", value, {
          root: true,
        });
      },
    },

    bemerkung: {
      get() {
        return this.$store.state.overlays.add.selected.bemerkung;
      },

      set(value) {
        this.$store.commit("overlays/updateBemerkung", value, { root: true });
      },
    },

    patient_name: {
      get() {
        return this.$store.state.overlays.add.selected.patient_name;
      },

      set(value) {
        this.$store.commit("overlays/updatePatientNeuName", value, {
          root: true,
        });
      },
    },

    patient_vorname: {
      get() {
        return this.$store.state.overlays.add.selected.patient_vorname;
      },

      set(value) {
        this.$store.commit("overlays/updatePatientNeuVorname", value, {
          root: true,
        });
      },
    },

    patient_geburtsdatum: {
      get() {
        return this.$store.state.overlays.add.selected.patient_geburtsdatum;
      },

      set(value) {
        this.$store.commit("overlays/updatePatientNeuGeburtsdatum", value, {
          root: true,
        });
      },
    },

    patient_telefon: {
      get() {
        return this.$store.state.overlays.add.selected.patient_telefon;
      },

      set(value) {
        this.$store.commit("overlays/updatePatientNeuTelefon", value, {
          root: true,
        });
      },
    },

    color: {
      get() {
        return this.$store.state.overlays.add.selected.color;
      },

      set(value) {
        this.$store.commit("overlays/updateColor", value, { root: true });
      },
    },

    patient: {
      get() {
        return this.$store.state.overlays.add.selected.patient;
      },

      set(value) {
        this.$store.commit("overlays/updatePatient", value, { root: true });
      },
    },

    mitarbeiter: {
      get() {
        return this.$store.state.overlays.add.selected.mitarbeiter;
      },

      set(value) {
        this.$store.commit("overlays/updateMitarbeiter", value, { root: true });
      },
    },

    heilmittel: {
      get() {
        return this.$store.state.overlays.add.selected.heilmittel;
      },

      set(value) {
        this.$store.commit("overlays/updateHeilmittel", value, { root: true });
      },
    },

    behandlungen: {
      get() {
        return this.$store.state.overlays.add.selected.behandlungen;
      },

      set(value) {
        this.$store.commit("overlays/updateBehandlungen", value, {
          root: true,
        });
      },
    },

    termine: {
      get() {
        return this.$store.state.overlays.add.selected.termine;
      },

      set(value) {
        this.$store.commit("overlays/updateTermine", value, {
          root: true,
        });
      },
    },

    titel: {
      get() {
        return this.$store.state.overlays.add.selected.name;
      },

      set(value) {
        this.$store.commit("overlays/updateName", value, {
          root: true,
        });
      },
    },

    ganztag: {
      get() {
        return this.$store.state.overlays.add.selected.ganztag;
      },

      set(value) {
        this.$store.commit("overlays/updateGanztag", value, {
          root: true,
        });
      },
    },

    patient_neu_flag: {
      get() {
        return this.$store.state.overlays.add.selected.patient_neu_flag;
      },

      set(value) {
        this.$store.commit("overlays/updatePatientNeuFlag", value, {
          root: true,
        });
      },
    },
  },
  methods: {
    ...mapMutations("overlays", [
      "addHeilmittel",
      "removeHeilmittel",
      "removeTermin",
      "clearTimeInput",
      "hideAddTerminShowSelectHeilmittel",
      "hideAddTerminShowSelectHeilmittelRepick",
      "recheckTermine",
    ]),
    ...mapMutations("termine", ["removeTemporaryAppointments"]),
    ...mapActions("patienten", ["Search"]),
    ...mapActions("overlays", ["CreateTermine", "closeAddTerminandReset"]),

    closeWindow() {
      // reset errors
      this.v$.$reset();

      // reset menu and selections
      this.selections = defaultSelectionsData();
      this.menu = defaultMenuData();

      this.closeAddTerminandReset();
    },

    handleRepick(item){
      this.switchToTermin(item);
      this.hideAddTerminShowSelectHeilmittelRepick(item);
    },

    addHeilmittelAndResetInput() {
      this.addHeilmittel();
      this.heilmittel = null;
    },

    closeErrorDialog() {
      this.errorDialog = false;
    },

    handleInputDateText(name) {
      if (this.selections[name]) {
        let m = moment(this.selections[name], "DD.MM.YYYY", true);
        if (m.isValid()) {
          this[name] = m.format("YYYY-MM-DD");

          setTimeout(() => {
            this.menu[name] = false;
          }, 200);
        } else {
          this[name] = null;
        }
      } else {
        this[name] = null;
      }

      // touch element
      this.v$[name].$touch();
    },

    handleFocusDateText(name) {
      var menu_names = [
        "start_date",
        "end_date",
        "patient_geburtsdatum",
      ].filter((e) => e !== name);

      setTimeout(() => {
        if (!this.menu[name]) {
          this.menu[name] = true;
        }
        for (const n of menu_names) {
          this.menu[n] = false;
        }
      }, 200);
    },

    handleInputDatePicker(name) {
      let focus_lkp = {
        start_date: ["startzeit", "enddatum"],
        end_date: ["endzeit", "mitarbeiter"],
        patient_geburtsdatum: ["heilmittel"],
      };

      // set selection
      this.selections[name] = moment(this[name]).format("DD.MM.YYYY");

      // close picker
      this.menu[name] = false;

      // focus next element
      let resolution_order = focus_lkp[name];
      for (var element in resolution_order) {
        if (this.$refs[resolution_order[element]]) {
          let next = resolution_order[element];
          this.$refs[next].focus();
          break;
        }
      }
    },

    isRemovable(item) {
      const locked = ["Physiotherap. Diagnostik (PD)", "Versorgungspauschale Blanko-VO"]
      if (item){
        return this.$store.getters["overlays/isRemovable"](item) && !locked.includes(item.bezeichnung);
      }
      return false;
    },

    editHeilmittelTime(item) {
      const curr_behandlungen = this.$store.getters["overlays/getBehandlungen"];
      const key = item.key;

      if ("time_dialog_" + key in this.$refs) {
        for (const beh of curr_behandlungen) {
          if (beh.key == key) {
            this.$refs["time_dialog_" + key].isActive = !this.$refs[
              "time_dialog_" + key
            ].isActive;
            break;
          }
        }
      }
    },

    rowClicked(d1, d2) {
      if (d2.isExpanded) {
        d2.expand(false);
      } else {
        d2.expand(true);
      }
    },

    behandlungenSummary(item) {
      let summary_parts = [];
      let counted_parts = [];
      let time_sum = 0;

      for (const beh of item.behandlungen) {
        summary_parts.push(beh.bezeichnung);
        time_sum += parseInt(beh.zeiteinheit, 10);
      }

      const map = summary_parts.reduce(
        (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
        new Map()
      );

      [...map.entries()].forEach((element) => {
        if (element[1] > 1) {
          counted_parts.push(element[1] + "x " + element[0]);
        } else {
          counted_parts.push(element[0]);
        }
      });

      // Time info
      let m = time_sum % 60;
      let h = (time_sum - m) / 60;

      return (
        h.toString() +
        "h " +
        (m < 10 ? "0" : "") +
        m.toString() +
        "min - " +
        counted_parts.join(" + ")
      );
    },

    row_classes(item) {
      if (!item.valid) {
        return "error_row";
      }
    },

    async create_Termin() {
      this.v$.$touch();

      let valid = true;
      // ensure there are no errors in Behandlungen eg. check validity of all treatments
      if (this.termintyp == "Behandlung") {
        let termine_selected = Boolean(this.termine.length != 0);
        let termine_valid = true;
        for (const termin of this.termine) {
          if (!termin.valid) {
            termine_valid = false;
            break;
          }
        }
        valid = termine_selected && termine_valid;
      }

      if (this.v$.$errors.length == 0 && valid) {
        this.disabled = true;

        // Send data to server
        let [errors, data] = await this.CreateTermine();

        // Check if error object is not empty
        if (Object.keys(errors).length > 0) {
          this.errorData = {};

          for (const [index, termin] of data.termine.entries()) {
            if (errors[index]) {
              this.errorData[index] = {
                name: termin.name,
                start: moment(termin.start.slice(0, -1)).format(
                  "DD.MM.YYYY HH:mm"
                ),
                end: moment(termin.end.slice(0, -1)).format("DD.MM.YYYY HH:mm"),
                errors: errors[index],
              };

              // Set error message
              termin.error = errors[termin.id];
            }
          }

          // Show error dialog
          this.errorDialog = true;
        } else {
          // reset menu and selections
          this.selections = defaultSelectionsData();
          this.menu = defaultMenuData();

          // ensure errors are reset after saving
          this.v$.$reset();
        }
        this.disabled = false;
      }
    },

    setMitarbeiterColor() {
      if (this.mitarbeiter) {
        this.color.hex = this.mitarbeiter.color;
        this.color.hexa = this.mitarbeiter.color + "FF";
      }
    },


    formatDate(value) {
      return value ? moment(String(value)).format("DD.MM.YYYY") : "";
    },

    patient_text(item) {
      return (
        "(" +
        item.kt_typ +
        ") " +
        item.name +
        ", " +
        item.vorname +
        " (" +
        this.formatDate(item.geburtsdatum) +
        ")"
      );
    },

    mitarbeiter_text: (item) => item.name + ", " + item.vorname,
    heilmittel_text: (item) => item.bezeichnung,

    setMitarbeiterStr(item) {
      item.mitarbeiter_str =
        item.mitarbeiter.name + ", " + item.mitarbeiter.vorname;

      this.recheckTermine();
    },

    deleteHeilmittelItem(item) {
      this.v$.behandlungen.$touch();
      this.removeHeilmittel(item.key);
    },

    deleteTerminItem(item) {
      this.v$.termine.$touch();
      this.removeTermin(item.key);
      this.recheckTermine();
    },

    clearPatientInput() {
      if (this.patient_neu_flag) {
        this.patient = null;
      } else {
        this.patient_name = "";
        this.patient_vorname = "";
        this.patient_telefon = "";
        this.patient_geburtsdatum = null;
        this.selections.patient_geburtsdatum = null;
      }
    },



    openEditAnzahlDialog(item) {
      this.currentTreatmentItem = item;
      this.editAnzahl = item.anzahl;
      this.anzahlDialog = true;
    },

    closeEditAnzahlDialog() {
      this.currentTreatmentItem = null;
      this.editAnzahl = null;
      this.anzahlDialog = false;
    },

    openEditZeiteinheitDialog(item) {
      this.currentTreatmentItem = item;
      this.editDauer = item.zeiteinheit;
      this.dauerDialog = true;
    },

    closeEditZeiteinheitDialog() {
      this.currentTreatmentItem = null;
      this.editDauer = null;
      this.dauerDialog = false;
    },

    saveAnzahl() {
      if (this.anzahlErrors.length == 0) {
        this.currentTreatmentItem.anzahl = Number(this.editAnzahl);
        this.closeEditAnzahlDialog();
      }
    },

    saveZeiteinheit() {
      if (this.dauerErrors.length == 0) {
        this.currentTreatmentItem.zeiteinheit = Number(this.editDauer);
        this.closeEditZeiteinheitDialog();
      }
    },


    setBlankoDefaultTreatments() {
      const defaultTreatments = ["Versorgungspauschale Blanko-VO", "Physiotherap. Diagnostik (PD)"]

      for (const treatment of this.heilmittelList){
        if (defaultTreatments.includes(treatment.bezeichnung)){
          this.heilmittel = treatment;
          this.addHeilmittel();
        }
        this.heilmittel = null;
      }

    },


    anzahlDisabled(item) {
      const locked = ["Versorgungspauschale Blanko-VO", "Physiotherap. Diagnostik (PD)"]
      return locked.includes(item.bezeichnung);
    },

    async refetchTimeUnits() {
      if (this.treatmentType === "Vollprivat") {
        await this.$store.dispatch("zeiteinheiten/GetVollprivatZeiteinheitenData");
      } else {
        // For both "Standard" and "Blanko" types
        const ktType = this.patient ? this.patient.kt_typ : null;
        const blanko = this.treatmentType === "Blanko";
        
        await this.$store.dispatch(
          "zeiteinheiten/GetTarifZeiteinheitenData",
          {
            ktType: ktType,
            blanko: blanko,
          }
        );
      }
    },

  },

  watch: {
    "menu.patient_geburtsdatum": function(val) {
      if (val) {
        val && setTimeout(() => (this.geburtsdatum_picker = "YEAR"));
      }
    },

    termintyp: function() {
      // Reset errors
      this.v$.$reset();
    },

    terminmode: function() {
      // Reset appointment list if mode is changed
      this.termine = [];
    },

    treatmentType: async function(new_value) {
      this.behandlungen = [];
      this.termine = [];
      await this.refetchTimeUnits();
      await this.removeTemporaryAppointments();

      if (new_value == "Blanko"){
        this.setBlankoDefaultTreatments();
      }

    },

    patient: async function(new_patient, old_patient) {
      if (!new_patient) {
        return;
      }

      let resetAndLoad = true;

      if (new_patient && old_patient) {
        if (new_patient.kt_typ == old_patient.kt_typ) {
          resetAndLoad = false;
        }

        if (new_patient.kt_typ != "PP" && this.treatmentType == "Vollprivat") {
          this.treatmentType = "Standard";
        }
      }

      if (resetAndLoad) {
        this.behandlungen = [];
        this.termine = [];
        await this.refetchTimeUnits();
      }
    },

    patient_neu_flag: async function(new_value) {
      if (new_value) {
        await this.refetchTimeUnits();
      }
    },

    heilmittel: function( newValue ) {
      if (newValue) {
        const locked = ["Physiotherap. Diagnostik (PD)", "Versorgungspauschale Blanko-VO"]
        if (locked.includes(newValue.bezeichnung)){
          this.heilmittel = null;
          this.$refs.heilmittel.reset()
        }
      }
    },

    async search_term(val) {
      if (val) {
        let key = null;
        if (this.patient) {
          key =
            "(" +
            this.patient.kt_typ +
            ") " +
            this.patient.name +
            ", " +
            this.patient.vorname +
            " (" +
            this.formatDate(this.patient.geburtsdatum) +
            ")";
        }

        if (val && val.length >= 2 && val != key) {
          this.loading = true;

          let searchData = {
            term: val,
            includeIncomplete: false,
          };

          await this.Search(searchData);
          this.loading = false;
        }
      }
    },
  },

  mounted() {},
};
</script>
<style>
.error_row {
  background-color: #ff8f8f33;
}

.error_entry {
  color: red;
}

.v-color-picker__alpha {
  display: none;
}
</style>
