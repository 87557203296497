import axios from "axios";
import moment from "moment";

const getDefaultState = () => {
  return {
    snackbars: {
      addEditSelect: false,
      addEditSuccess: false,
      addEditRepick: false,
      change_success: false,
      mitarbeiter_busy: false,
    },

    termintypes: ["Behandlung", "Intern"],
    terminmodes: ["Standardtermin", "Serientermin"],
    treatmentTypes: ["Standard", "Blanko", "Vollprivat"],

    add: {
      active: false,
      visible: false,
      disabled: false,
      selecting: false,
      selectHeilmittelActive: false,
      repicking: false,
      repick_id: null,

      selected: {
        termin_key: 0,
        termintyp: "Behandlung",
        terminmode: "Standardtermin",
        treatmentType: "Standard",
        patient: null,
        patient_neu_flag: false,
        patient_name: "",
        patient_vorname: "",
        patient_geburtsdatum: null,
        patient_telefon: "",
        mitarbeiter: null,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        heilmittel: null,
        behandlungen: [],
        behandlungen_selected: [],
        bemerkung: "",
        name: "",
        ganztag: false,
        color: {
          hexa: "#B43962FF",
        },
        termine: [],
      },
    },

    edit: {
      active: false,
      visible: false,
      disabled: false,
      selecting: false,
      selectHeilmittelActive: false,
      repicking: false,
      repick_id: null,

      rawData: null,

      selected: {
        behandlungen: [],
        behandlungen_selected: [],
        heilmittel: null,
        terminmode: "Standardtermin",
        termine: [],
        termintyp: "Behandlung",
        patient_data: null,
        treatmentType: "Standard",
        patient: null,
      }
    },

    drag_confirmation: {
      previous: null,
      active: false,
      visible: false,
      drag: false,
      drag_category: false,
      extend: false,
      termin: null,
      original_start: null,
      original_end: null,
      original_category: null,
      errors: [],
      move: false,
      move_category: false,
      loading: false,
    },

    add_patient: {
      active: false,
      item: null,
    },

    add_doctor: {
      active: false,
      defaults: null,
      added: [],
    },

    termin_move: {
      active: false,
      previous: null,
      termin: {
        current: null,
        new: null,
      },
    },

    patienten_overview: {
      active: false,
      loading: false,
      search: "",
    },

    prescriptionOverview: {
      active: false,
      loading: false,
    },

    patient_termine: {
      active: false,
      patient: null,
      previous: null,
    },

    nacherfassung: {
      active: false,
      loading: false,
      status: null,
      search: "",
    },

    settings: {
      active: false,
      loading: false,
      timeunits: {
        selected: {
          heilmittel: null,
        },
      },
    },

    excessPaymentNotification: {
      active: false,
      warningType: null,
      oldValue: "",
      newValue: "",
    },

    unmatchedTarifeNotification: {
      active: false,
    },

    rezept: {
      active: false,
      item: null,
    },

    timeout: {
      active: false,
    },

    termin_reject: {
      active: false,
      termin_id: null,
    },

    tarifeNacherfassung: {
      active: false,
      groupId: null,
    }
  };
};

// Initial state
const state = getDefaultState();

const getters = {

  excessPaymentNotificationActive: (state) => {
    return state.excessPaymentNotification.active;
  },

  excessPaymentNotificationWarningType: (state) => {
    return state.excessPaymentNotification.warningType;
  },

  excessPaymentNotificationOldValue: (state) => {
    return state.excessPaymentNotification.oldValue;
  },

  excessPaymentNotificationNewValue: (state) => {
    return state.excessPaymentNotification.newValue;
  },

  unmatchedTarifeNotificationActive: (state) => {
    return state.unmatchedTarifeNotification.active;
  },

  dragConfirmationActive: (state) => {
    return state.drag_confirmation.active;
  },

  dragConfirmationVisible: (state) => {
    return state.drag_confirmation.visible;
  },

  dragConfirmationLoading: (state) => {
    return state.drag_confirmation.loading;
  },

  dragConfirmationDrag: (state) => {
    return state.drag_confirmation.drag;
  },

  dragCategoryConfirmationDrag: (state) => {
    return state.drag_confirmation.drag_category;
  },

  dragConfirmationMove: (state) => {
    return state.drag_confirmation.move;
  },

  dragCategoryConfirmationMove: (state) => {
    return state.drag_confirmation.move_category;
  },

  dragConfirmationExtend: (state) => {
    return state.drag_confirmation.extend;
  },

  dragConfirmationTermin: (state) => {
    return state.drag_confirmation.termin;
  },

  dragConfirmationErrors: (state) => {
    return state.drag_confirmation.errors;
  },

  terminRejectActive: (state) => {
    return state.termin_reject.active;
  },

  terminRejectId: (state) => {
    return state.termin_reject.termin_id;
  },

  patientTerminePatient: (state) => {
    return state.patient_termine.patient;
  },

  tarifeNacherfassungActive: (state) => {
    return state.tarifeNacherfassung.active;
  },

  tarifeNacherfassungGroupId: (state) => {
    return state.tarifeNacherfassung.groupId;
  },

  doctorAddActive: (state) => {
    return state.add_doctor.active;
  },

  doctorAddDefaults: (state) => {
    return state.add_doctor.defaults;
  },

  doctorLastAdded: (state) => {
    if (state.add_doctor.added.length > 0) {
      return state.add_doctor.added[state.add_doctor.added.length - 1];
    }
    return null;
  },

  patientAddActive: (state) => {
    return state.add_patient.active;
  },

  patientNacherfassungItem: (state) => {
    return state.add_patient.item;
  },

  prescriptionOverviewActive: (state) => {
    return state.prescriptionOverview.active;
  },

  nacherfassungActive: (state) => {
    return state.nacherfassung.active;
  },

  nacherfassungStatus: (state) => {
    return state.nacherfassung.status;
  },

  nacherfassungSearch: (state) => {
    return state.nacherfassung.search;
  },

  patientenOverviewSearch: (state) => {
    return state.patienten_overview.search;
  },

  settingsActive: (state) => {
    return state.settings.active;
  },

  patientenOverviewActive: (state) => {
    return state.patienten_overview.active;
  },

  patientTermineActive: (state) => {
    return state.patient_termine.active;
  },

  timeOutActive: (state) => {
    return state.timeout.active;
  },

  rezeptActive: (state) => {
    return state.rezept.active;
  },

  addIsVisible: (state) => {
    return state.add.visible;
  },

  addIsDisabled: (state) => {
    return state.add.disabled;
  },

  editIsVisible: (state) => {
    return state.edit.visible;
  },

  editIsDisabled: (state) => {
    return state.edit.disabled;
  },

  isRemovable: (state) => (item) => {
    let termine = state.add.active ? state.add.selected.termine : state.edit.selected.termine;

    for (const termin of termine) {
      for (const beh of termin.behandlungen) {
        if (beh.key == item.key) {
          return false;
        }
      }
    }

    return true;
  },

  behandlungenInUse: (state) => (item) => {
    let mod = state.add.active ? state.add.selected : state.edit.selected;
    let counter = 0;
    for (const termin of mod.termine) {
      for (const beh of termin.behandlungen) {
        if (beh.key == item.key) {
          counter += 1;
        }
      }
    }
    return counter;
  },


  behandlungenSelectedLength: (state) => {
    let mod = state.add.active ? state.add.selected : state.edit.selected;

    let selected = [];
    if (mod.behandlungen) {
      selected = mod.behandlungen_selected;
    }

    let time_sum = 0;
    for (const beh of selected) {
      time_sum += parseInt(beh.zeiteinheit, 10);
    }

    let m = time_sum % 60;
    let h = (time_sum - m) / 60;

    return h.toString() + "h " + (m < 10 ? "0" : "") + m.toString() + "min";
  },

  addSelectHeilmittelIsVisible: (state) => {
    return state.add.selectHeilmittelActive;
  },

  editSelectHeilmittelIsVisible: (state) => {
    return state.edit.selectHeilmittelActive;
  },

  moveIsActive: (state) => {
    return state.termin_move.active;
  },

  addIsActive: (state) => {
    return state.add.active;
  },

  editIsActive: (state) => {
    return state.edit.active;
  },

  addIsSelecting: (state) => {
    return state.add.selecting;
  },

  getTerminTypes: (state) => {
    return state.termintypes;
  },

  getTerminModes: (state) => {
    return state.terminmodes;
  },

  getSelectedTermine: (state) => {
    return state.add.selected.termine;
  },

  getBehandlungen: (state) => {
    return state.add.selected.behandlungen;
  },

  addGetRemainingBehandlungen: (state) => {
    let behandlungen = state.add.selected.behandlungen;
    let behandlungen_lkp = {};
    behandlungen.forEach(function (value, i) {
      behandlungen_lkp[value.key] = i;
      state.add.selected.behandlungen[i].remaining = value.anzahl;
    });

    for (const termin of state.add.selected.termine) {
      if (state.add.repick_id != termin.id) {
        for (const beh of termin.behandlungen) {
          let i = behandlungen_lkp[beh.key];
          state.add.selected.behandlungen[i].remaining -= 1;
        }
      }
    }

    return state.add.selected.behandlungen.filter((beh) => beh.remaining > 0);
  },

  editGetRemainingBehandlungen: (state) => {
      let behandlungen = state.edit.selected.behandlungen;
      let behandlungen_lkp = {};

      behandlungen.forEach(function (value, i) {
        behandlungen_lkp[value.key] = i;
        state.edit.selected.behandlungen[i].remaining = value.anzahl;
      });

      
      for (const termin of state.edit.selected.termine) {
        if (state.edit.repick_id != termin.id){
          for (const beh of termin.behandlungen) {
            let i = behandlungen_lkp[beh.key];
            state.edit.selected.behandlungen[i].remaining -= 1;
          }
        }
      }

      return state.edit.selected.behandlungen.filter((beh) => beh.remaining > 0);
    },

    getRepickItem: (state) => {
      let mod = state.add.active ? state.add : state.edit;
      let termine = mod.selected.termine;
      let repick_id = mod.repick_id;

      for (const termin of termine) {
        if (termin.id == repick_id) {
          return termin;
        }
      }
    },

  getBehandlungenDuration: (state) => {
    let duration = 0;

    let behandlungen_selected = state.add.active ? state.add.selected.behandlungen_selected : state.edit.selected.behandlungen_selected;

    behandlungen_selected.forEach((element) => {
      duration += parseInt(element.zeiteinheit, 10);
    });

    return duration;
  },

  getRepickId: (state) => {
    if (state.add.active) {
      return state.add.repick_id;
    }
    else if (state.edit.active) {
      return state.edit.repick_id;
    }
    return null;
  },

  getPatientId: (state) => {
    let mod = state.add.active ? state.add : state.edit;
    return mod.selected.patient ? mod.selected.patient.id : null;
  },

  getTerminMoveId: (state) => {
    return state.termin_move.termin.current.id;
  },

  getTreatmentTypes: (state) => (kt_typ, le_typ) => {
    // If le_typ is null, return an empty list
    if (le_typ === null) {
      return [];
    }
  
    let treatmentTypes = [...state.treatmentTypes];
  
    // Define valid types for Blanko
    const validLeTypesForBlanko = ["22", "26"];
    
    // Remove Blanko if le_typ is not valid
    if (!validLeTypesForBlanko.includes(le_typ)) {
      treatmentTypes = treatmentTypes.filter(type => type !== "Blanko");
    }
  
    // Only apply kt_typ based filtering if kt_typ is provided
    if (kt_typ !== null && kt_typ !== undefined) {
      const validKtTypesForBlanko = ["KK", "S", "PBeaKK"];
      
      // Remove Blanko if kt_typ is not valid
      if (!validKtTypesForBlanko.includes(kt_typ)) {
        treatmentTypes = treatmentTypes.filter(type => type !== "Blanko");
      }
      
      // Remove Vollprivat if kt_typ is not PP
      if (kt_typ !== "PP") {
        treatmentTypes = treatmentTypes.filter(type => type !== "Vollprivat");
      }
    }
  
    return treatmentTypes;
},

  getTerminMoveDuration: (state) => {
    let s = moment(state.termin_move.termin.current.start).utc(true);
    let e = moment(state.termin_move.termin.current.end).utc(true);
    return e.diff(s, "minutes");
  },

  isTerminInPast: () => (date, hour, minute) => {
    let now = moment().utc(true);
    let picked = moment(date)
      .utc(true)
      .hour(hour)
      .minute(minute);

    return picked.isSameOrBefore(now);
  },

  getRezeptNacherfassungItem: (state) => {
    return state.rezept.item;
  },

};

const actions = {


  async getAppointmentsData({ state, rootGetters }) {

    let sv = state.add.active ? state.add.selected : state.edit.selected;
    
    let data = {
      kunde: rootGetters['auth/currentUser'].user_id,
      patient: null,
      incompletePatientData: null,
      termintyp: sv.termintyp,
      termine: [],
      vollprivat: sv.treatmentType == "Vollprivat",
      blanko: sv.treatmentType == "Blanko",
      bemerkung: sv.bemerkung ? sv.bemerkung : "",
    }

    if (sv.termintyp == "Intern") {

      let termin = {
        name: sv.name,
        mitarbeiter: sv.mitarbeiter ? sv.mitarbeiter.id : null,
        ganztag: sv.ganztag,
        color: sv.color.hex,
        start: null,
        end: null,
        behandlungen: null,
      };

      // Create moment objects from date string
      let s = moment(sv.start_date).utc(true);
      let e = moment(sv.end_date).utc(true);

      // If not ganztag, add time information
      if (!sv.ganztag) {
        s.hour(sv.start_time.substring(0, 2)).minute(sv.start_time.substring(3));
        e.hour(sv.end_time.substring(0, 2)).minute(sv.end_time.substring(3));
      }
      else {
        // Set to start and end of day
        s.hour(0).minute(0).second(0);
        e.hour(23).minute(59).second(59);
      }

      // Convert to ISO string
      termin.start = s.toISOString();
      termin.end = e.toISOString();

      data.termine.push(termin);
    }
    else if (sv.termintyp == "Behandlung") {
      // Set patient data
      if (sv.patient) {
        data.patient = sv.patient.id;
      }
      else if (sv.patient_neu_flag) {
        data.incompletePatientData = {
          name: sv.patient_name,
          vorname: sv.patient_vorname,
          geburtsdatum: moment(sv.patient_geburtsdatum).format("YYYY-MM-DD"),
          telefon: sv.patient_telefon,
        };
      }

      for (const element of sv.termine) {
        let counts = {};

        let termin = {
          name: null,
          mitarbeiter: element.mitarbeiter ? element.mitarbeiter.id : null,
          ganztag: false,
          color: "",
          start: null,
          end: null,
          behandlungen: [],
        };

        for (const bh of element.behandlungen) {
          termin.behandlungen.push(
            {
              name: bh.bezeichnung,
              duration: bh.zeiteinheit,
            }
          );

          // Calculate duration and count for each heilmittel
          // This is used to calculate the name of the termin and the end datetime
          var abk = bh.abkuerzung;
          counts[abk] = counts[abk] ? counts[abk] + 1 : 1;
        }

        // Build/Save name
        let name = []
        Object.entries(counts).reduce((parts, [abk, count], index) => {
          if (index !== 0) {
            parts.push("/");
          }
          if (count > 1) {
            parts.push(count.toString() + "x");
          }
          parts.push(abk);
          return parts;
        }, name);

        termin.name = name.join("");

        // Create moment objects from date string
        let s = moment(element.start_date)
          .hour(element.start_time.substring(0, 2))
          .minute(element.start_time.substring(3))
          .utc(true);
        let e = moment(element.end_date)
          .hour(element.end_time.substring(0, 2))
          .minute(element.end_time.substring(3))
          .utc(true);

        // Convert to ISO string
        termin.start = s.toISOString();
        termin.end = e.toISOString();
        termin.status = element.status ? element.status : "Temporär"
        data.termine.push(termin);
      }
    }

    return data;
  },



  async CreateTermine({ commit, rootGetters, dispatch}) {
    let requestConfig = {
      headers: rootGetters['auth/authHeaders'],
    };

    let appointmentsData = await dispatch("getAppointmentsData");

    try {
      await axios.post("termine/", appointmentsData, requestConfig);
      commit("termine/removeTemporaryAppointments", {}, { root: true });
      dispatch("termine/getAppointmentData", null, { root: true })
      commit("closeAddTermin");
      commit("resetState");
      return [{}, appointmentsData]
    } catch (error) {
      return [error.response.data, appointmentsData]
    }

  },

  closeExcessPaymentNotificationAndReset({ commit }) {
    commit("closeExcessPaymentNotification");
    commit("resetExcessPaymentNotification");
  },

  closeUnmatchedTarifeNotification({ commit }) {
    commit("closeUnmatchedTarifeNotification");
  },


  handleMovedTermin({ commit, state }, data) {
    commit("addMovedTermin", data);
    commit("termine/removeTemporaryAppointments", {}, { root: true });
    commit("termine/addTemporaryAppointment", state.termin_move.termin.new.obj, { root: true });
  },

  handleMoveTerminReturn({ commit }) {
    commit("termine/removeTemporaryAppointments", {}, { root: true });
    commit("closeTerminMoveAndReturn");
  },

  moveOpenDragConfirmation({ commit, state }) {
    let termin_lkp = state.termin_move.termin;
    // Move: same category but different time - move-category: different category with different time
    let type = termin_lkp.current.category == termin_lkp.new.mitarbeiter_str ? "move" : "move-category";

    // Save original data
    let data = {
      type: type,
      termin: termin_lkp.current,
      original_start: moment(termin_lkp.current.start).format("YYYY-MM-DDTHH:mm"),
      original_end: moment(termin_lkp.current.end).format("YYYY-MM-DDTHH:mm"),
      original_category: termin_lkp.current.category,
      errors: [],
      previous: "TerminMove",
    };

    state.termin_move.active = false;

    commit("openDragConfirmation", data);
  },

  closeDragConfirmationAndResetTermin({ commit, state }) {
    if (state.drag_confirmation.active) {
      commit("resetDragTermin")
      commit("closeDragConfirmation");
    }
  },

  async closeDragConfirmationAndUpdateTermin({ commit, dispatch, state }) {
    state.drag_confirmation.loading = true;

    if (state.drag_confirmation.active) {
      if (state.drag_confirmation.move || state.drag_confirmation.move_category) {
        let termin_lkp = state.termin_move.termin;

        state.termin_move.termin.current.start = termin_lkp.new.obj.start;
        state.termin_move.termin.current.end = termin_lkp.new.obj.end;
        state.termin_move.termin.current.category = termin_lkp.new.obj.category;

        commit("termine/removeTemporaryAppointments", {}, { root: true });
      }

      let responseData = await dispatch("termine/updateTerminEntry", state.drag_confirmation.termin, { root: true });

      // Ensure that termin_move is not displayed and data is wiped
      if (state.drag_confirmation.previous) {
        if (state.drag_confirmation.previous == "TerminMove") {
          // After saving show PatientTermine
          state.drag_confirmation.previous = state.termin_move.previous
          // Reset termin_move previous 
          state.termin_move.previous = null;
        }
      }

      commit("closeTerminMoveAndReturn");
      commit("closeDragConfirmation");
      state.drag_confirmation.loading = false;
      commit("openExcessPaymentNotification", responseData.warnings);
    }
  },

  closeAddTerminandReset({ commit, state }) {
    if (state.add.active) {
      commit("closeAddTermin");
      commit("termine/removeTemporaryAppointments", {}, { root: true });
      commit("resetState");
    }
  },

  closeEditTerminandReset({ commit, state }) {
    if (state.edit.active) {
      commit("closeEditTermin");
      commit("termine/removeTemporaryAppointments", {}, { root: true });
      commit("resetEditState");
    }
  },

  setTerminRejectId({ commit }, id) {
    commit("setTerminRejectId", id);
  },

  closeTerminReject({ commit, state }) {
    if (state.termin_reject.active) {
      commit("closeTerminReject");
    }
  },

  openAndLoadDragConfirmation({ commit }, data) {
    commit("openDragConfirmation", data);
  },

  async openNacherfassung({ commit }, data = {}) {
    commit("openNacherfassung", data);
  },

  async openAndLoadSettings({ commit }) {
    commit("openSettings");
  },

  closeAndClearSettings({ commit }) {
    commit("closeSettings");
    commit("updateSettingsHeilmittel", null);
  },

  closeAndClearNacherfassung({ commit }) {
    commit("closeNacherfassung");
  },

  closeAndClearPatientenOverview({ commit }) {
    commit("closePatientenOverview");
    commit("patienten/clearPatienten", null, { root: true });
  },

  closeAndClearPatientTermine({ commit }, closeAll) {
    commit("closePatientTermine", closeAll);
  },

  closeAndClearRezept({ commit}) {
    commit("closeRezept");
  },

  async openAndLoadAppointmentEdit({ commit, dispatch}, groupId) {
    dispatch("termine/getAppointmentGroup", groupId, { root: true }).then((data) => {
      commit("openAppointmentEdit", data);
    });
  },

  async isAppointmentAvailable ({dispatch, state}, data){

    let availabilityData = {
      valid: false,
      available_mitarbeiter: [],
      message: "",
    };

    let start = moment(data.date).utc(true).hour(data.hour).minute(data.minute);
    let end = start.clone().add(data.duration, "m");

    // Two cases:
    // 1. We need to check the availability of a single appointment e.g. as part of a move operation

    if (state.termin_move.active){
      let newAppointment = {...state.termin_move.termin.current};      
      // Adjust data that was changed e.g. start, end, employee
      newAppointment.start = start.format("YYYY-MM-DDTHH:mm:ss");
      newAppointment.end = end.format("YYYY-MM-DDTHH:mm:ss");
      newAppointment.mitarbeiter = data.mitarbeiterId;

      // Check availability
      let responseData = await dispatch("termine/validateAppointment", newAppointment, { root: true });
      
      availabilityData.valid = responseData.isValid;
      availabilityData.available_mitarbeiter = new Set(responseData.employeesAvailability[newAppointment.id])

      if (!availabilityData.valid){
        availabilityData.message = responseData.errors[newAppointment.id][0]
      }

    }

    // 2. Ween need to check the availability of single appointment as part of a new/edited appointment group
    if (state.add.active || state.edit.active){
      let mod = state.add.active ? state.add : state.edit;
      let appointmentsData = await dispatch("getAppointmentsData");


      // Add groupId if available to ignore appointments that are being edited
      if(mod.rawData){
        appointmentsData.id = mod.rawData.id;
      }
      
      let idx = 0;
      // If a repickId is provided, it means that the appointment is being edited and we need to replace all edited data
      if (data.repickId){

        // repickId can be used to find the correct index of the appointment that is being edited
        idx = Math.abs(data.repickId) - 1;
        
        // Find the appointment that is being edited
        appointmentsData.termine[idx].start = start.format("YYYY-MM-DDTHH:mm:ss");
        appointmentsData.termine[idx].end = end.format("YYYY-MM-DDTHH:mm:ss");
        appointmentsData.termine[idx].mitarbeiter = data.mitarbeiterId;
      }
      else {        
        // Otherwise, we need to add the new appointment to the list of appointments
        // This can be a dummy as the actual appointment will be added later
        appointmentsData.termine.push({
          name: "Temp",
          mitarbeiter: data.mitarbeiterId,
          ganztag: false,
          start: start.format("YYYY-MM-DDTHH:mm:ss"),
          end: end.format("YYYY-MM-DDTHH:mm:ss"),
          behandlungen: null,
        });

        idx = appointmentsData.termine.length - 1;
      }

      let responseData = await dispatch("termine/validateAppointments", appointmentsData, { root: true });
      // This time ignore the valid flag and only check if there are errors related to the new appointment

      availabilityData.available_mitarbeiter = new Set(responseData.employeesAvailability[idx]);

      if (responseData.errors[idx]){
        availabilityData.message = responseData.errors[idx][0];
      } else {
        availabilityData.valid = true;
      }
    }

    return availabilityData;
  }
  
};

const mutations = {

  openExcessPaymentNotification(state, warningData) {
    // Will open the notification only if there is a relevant warning
    if (warningData.excessPayment){
      state.excessPaymentNotification.warningType = "excessPayment";
      state.excessPaymentNotification.oldValue = warningData.excessPayment.oldValue;
      state.excessPaymentNotification.newValue = warningData.excessPayment.newValue;
      state.excessPaymentNotification.active = true;
    }
    else if (warningData.groupRemovedWithExcessPayment){
      state.excessPaymentNotification.warningType = "groupRemovedWithExcessPayment";
      state.excessPaymentNotification.active = true;
    }
  },

  closeExcessPaymentNotification(state) {
    state.excessPaymentNotification.active = false;
  },


  openUnmatchedTarifeNotification(state) {
    state.unmatchedTarifeNotification.active = true;
  },

  closeUnmatchedTarifeNotification(state) {
    state.unmatchedTarifeNotification.active = false;
  },

  closeDragConfirmation(state) {
    state.drag_confirmation.visible = false;
    state.drag_confirmation.active = false;
    state.drag_confirmation.drag = false;
    state.drag_confirmation.drag_category = false;

    state.drag_confirmation.move = false;
    state.drag_confirmation.move_category = false;

    state.drag_confirmation.extend = false;
    state.drag_confirmation.termin = null;
    state.drag_confirmation.original_start = null;
    state.drag_confirmation.original_end = null;
    state.drag_confirmation.original_category = null;
    state.drag_confirmation.errors = [];

    if (state.drag_confirmation.previous) {
      if (state.drag_confirmation.previous == "TerminMove") {
        state.termin_move.active = true;
      } else if (state.drag_confirmation.previous == "PatientTermine") {
        state.patient_termine.active = true;
      }
      else if (state.drag_confirmation.previous == "Nacherfassung") {
        state.nacherfassung.active = true;
      }

      state.drag_confirmation.previous = null;
    }
  },

  resetDragTermin(state) {
    state.drag_confirmation.termin.start = moment(state.drag_confirmation.original_start).format("YYYY-MM-DDTHH:mm:ss");
    state.drag_confirmation.termin.end = moment(state.drag_confirmation.original_end).format("YYYY-MM-DDTHH:mm:ss");
    state.drag_confirmation.termin.category = state.drag_confirmation.original_category;
    state.drag_confirmation.termin.mitarbeiter = this.getters["mitarbeiter/getMitarbeiterByCategory"](state.drag_confirmation.termin.category).id
  },

  openDragConfirmation(state, data) {
    state.drag_confirmation.active = true;
    state.drag_confirmation.visible = true;
    state.drag_confirmation.termin = data.termin;
    if (data.type == "drag") {
      state.drag_confirmation.drag = true;
    }

    if (data.type == "drag-category") {
      state.drag_confirmation.drag_category = true;
    }

    if (data.type == "extend") {
      state.drag_confirmation.extend = true;
    }

    if (data.type == "move") {
      state.drag_confirmation.move = true;
    }

    if (data.type == "move-category") {
      state.drag_confirmation.move_category = true;
    }

    state.drag_confirmation.original_start = data.original_start;
    state.drag_confirmation.original_end = data.original_end;
    state.drag_confirmation.original_category = data.original_category;
    state.drag_confirmation.errors = data.errors;

    if (data.previous) {
      state.drag_confirmation.previous = data.previous;

      if (data.previous == "TerminMove") {
        state.termin_move.active = false;
      }
    }
  },

  setTerminRejectId(state, id) {
    state.termin_reject.termin_id = id;
  },

  openSettings(state) {
    state.settings.active = true;
  },

  closeSettings(state) {
    state.settings.active = false;
  },

  openTarifeNacherfassung(state, groupId) {
    state.tarifeNacherfassung.active = true;
    state.tarifeNacherfassung.groupId = groupId;
  },

  closeTarifeNacherfassung(state) {
    state.tarifeNacherfassung.active = false;
    state.tarifeNacherfassung.groupId = null;
  },

  openNacherfassung(state, params = {}) {
    if (params.status) {
      state.nacherfassung.status = params.status;
    }

    if (params.search) {
      state.nacherfassung.search = params.search;
    }

    state.nacherfassung.active = true;
    state.nacherfassung.loading = true;
  },

  closeNacherfassung(state) {
    state.nacherfassung.active = false;
    state.nacherfassung.status = null;
    state.nacherfassung.search = "";
  },

  updateNacherfassungSearch(state, value) {
    state.nacherfassung.search = value;
  },

  updatePatientenOverviewSearch(state, value) {
    state.patienten_overview.search = value;
  },

  openPrescriptionOverview(state) {
    state.prescriptionOverview.active = true;
  },

  closePrescriptionOverview(state) {
    state.prescriptionOverview.active = false;
    state.prescriptionOverview.loading = false;
  },

  openAddPatient(state, item = null) {
    state.add_patient.active = true;
    state.add_patient.item = item;
  },

  openAddDoctor(state, defaults) {
    state.add_doctor.active = true;
    state.add_doctor.defaults = defaults;
  },

  closeAddDoctor(state) {
    state.add_doctor.active = false;
    state.add_doctor.defaults = null;
  },

  addLastAddedDoctor(state, arztNum) {
    state.add_doctor.added.push(arztNum);
  },

  openPatientenOverview(state) {
    state.patienten_overview.active = true;
  },

  closeAddPatient(state) {
    state.add_patient.active = false;
    state.add_patient.item = null;
  },

  closePatientenOverview(state) {
    state.patienten_overview.active = false;
    state.patienten_overview.search = "";
  },

  openAppointmentEdit(state, data) {
    state.edit.rawData = data

    // TODO: Think about moving this to a more appropriate place
    // Set treatment type
    if (state.edit.rawData.blanko) {
      state.edit.selected.treatmentType = "Blanko";
    } else if (state.edit.rawData.vollprivat) {
      state.edit.selected.treatmentType = "Vollprivat";
    } else {
      state.edit.selected.treatmentType = "Standard";
    }

    // Set Patient
    state.edit.selected.patient = state.edit.rawData.patient;

    state.edit.active = true;
    state.edit.visible = true;
  },

  openPatientTermine(state, data) {
    state.patient_termine.active = true;
    state.patient_termine.patient = data.patient;
    state.patient_termine.previous = data.componentName;

    if (data.componentName == "PatientenOverview") {
      state.patienten_overview.active = false;
    }
  },

  closePatientTermine(state, closeAll) {
    // Hide
    state.patient_termine.active = false;

    if (closeAll) {
      state.patienten_overview.search = "";
    }
    else {
      // Activate previous view
      if (state.patient_termine.previous == "PatientenOverview"){
        if (state.nacherfassung.active) {
          // clear nacherfassung search
          state.patienten_overview.search = "";
        }
        else {
          state.patienten_overview.active = true;
        }
      }
    }
  },

  openAddRezept(state, item) {
    state.rezept.active = true;
    state.rezept.item = item;
  },

  closeRezept(state) {
    state.rezept.active = false;
    state.rezept.item = null;
  },

  openTimeout(state) {
    if (!state.timeout.active) {
      state.timeout.active = true;
    }
  },

  closeTimeout(state) {
    state.timeout.active = false;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  resetStateAdd(state) {
    Object.assign(state.add, getDefaultState().add);
  },

  resetEditState(state) {
    Object.assign(state.edit, getDefaultState().edit);
  },

  resetExcessPaymentNotification(state) {
    Object.assign(state.excessPaymentNotification, getDefaultState().excessPaymentNotification);
  },

  clearTimeInput(state) {
    state.add.selected.start_time = null;
    state.add.selected.end_time = null;
  },

  closeAddTermin(state) {
    state.add.actions = false;
    state.add.disabled = false;
    state.add.visible = !state.add.visible;
  },

  closeEditTermin(state) {
    Object.assign(state.edit, getDefaultState().edit);
  },

  openTerminReject(state) {
    state.termin_reject.active = true;
  },

  closeTerminReject(state) {
    state.termin_reject.active = false;
  },

  hideEditTerminShowSelectHeilmittel(state) {
    state.edit.visible = false;
    state.edit.selecting = false;
    state.snackbars.edit_success = false;

    // Set all available Heilmittel as selected
    state.edit.selected.behandlungen_selected = this.getters["overlays/editGetRemainingBehandlungen"];
    state.edit.selectHeilmittelActive = true;
  },


  hideEditTerminShowSelectHeilmittelRepick(state, item) {
    state.edit.visible = false;

    // Set selected_heilmittel
    state.edit.selected.behandlungen_selected = [];
    for (const beh of item.behandlungen) {
      state.edit.selected.behandlungen_selected.push(beh);
    }

    state.edit.selectHeilmittelActive = true;
    state.edit.repicking = true;
    state.edit.repick_id = item.id;
  },

  hideAddTerminShowSelectHeilmittel(state) {
    state.add.visible = false;
    state.add.selecting = false;
    state.snackbars.addEditSuccess = false;

    // Set all available Heilmittel as selected
    state.add.selected.behandlungen_selected = this.getters["overlays/addGetRemainingBehandlungen"];
    state.add.selectHeilmittelActive = true;
  },

  hideAddTerminShowSelectHeilmittelRepick(state, item) {
    state.add.visible = false;

    // Set selected_heilmittel
    state.add.selected.behandlungen_selected = [];
    for (const beh of item.behandlungen) {
      state.add.selected.behandlungen_selected.push(beh);
    }

    state.add.selectHeilmittelActive = true;
    state.add.repicking = true;
    state.add.repick_id = item.id;
  },

  closeSelectHeilmittel(state) {
    let mod = state.add.active ? state.add : state.edit;

    mod.visible = true;
    mod.selectHeilmittelActive = false;
    mod.selecting = false;
    mod.repicking = false;
    mod.repick_id = null;
  },

  hideAddEditTerminWithSnackbar(state) {
    let mod = state.add.active ? state.add : state.edit;

    mod.visible = false;
    mod.selectHeilmittelActive = false;
    mod.selecting = false;

    if (mod.repicking) {
      state.snackbars.addEditRepick = true;
    } else {
      state.snackbars.addEditSelect = true;
    }
    
    state.snackbars.addEditSuccess = false;
  },

  openTerminMove(state, data) {
    // Hide previous views
    if (data.componentName == "PatientTermine") {
      state.patient_termine.active = false;
    }

    if (data.componentName == "Nacherfassung") {
      state.nacherfassung.active = false;
    }

    state.termin_move.previous = data.componentName;
    state.termin_move.active = true;
    state.termin_move.termin.current = data.termin;
  },

  closeTerminMoveAndReturn(state) {
    // Hide termin move
    state.termin_move.active = false;

    // Activate previous view
    if (state.termin_move.previous == "PatientTermine") {
      state.patient_termine.active = true;
    }
    if (state.termin_move.previous == "Nacherfassung") {
      state.nacherfassung.active = true;
    }

    // Reset previous view information
    state.termin_move.previous = null;
    state.termin_move.termin.current = null;
    state.termin_move.termin.new = null;
  },

  showAddEditTerminWithSnackbarStatus(state, status) {
    let mod = state.add.active ? state.add : state.edit;
    mod.visible = true;

    if (mod.repicking) {
      state.snackbars.addEditRepick = false;
      module.repicking = false;
    }

    if (mod.selecting) {
      state.snackbars.addEditSelect = false;
      mod.selecting = false;
    }

    if (status == "added") {
      state.snackbar.addEditSuccess = true;
    }

    if (status == "changed") {
      state.snackbar.change_success = true;
    }
  },

  openAddTermin(state) {
    state.add.visible = true;
    state.add.active = true;
  },

  addMovedTermin(state, data) {
    // Create termin_move.termin.new lkp used in "moveOpenDragConfirmation"
    let [h_a, m_a] = data.start_str.split(":");
    let termin = {
      start_date: moment(data.date),
      start_time: data.start_str,
      start: moment(data.date)
        .hour(h_a)
        .minute(m_a),
      mitarbeiter: null,
      mitarbeiter_str: "-",
      available_mitarbeiter: data.available_mitarbeiter,
    };

    if ("mitarbeiter" in data) {
      termin.mitarbeiter = data.mitarbeiter;
      termin.mitarbeiter_str = data.mitarbeiter.name + ", " + data.mitarbeiter.vorname;
    }

    let termin_lkp = state.termin_move.termin;

    let duration = 0;
    if ("start" in termin_lkp.current && "end" in termin_lkp.current) {
      duration = moment(termin_lkp.current.end).utc(true) - moment(termin_lkp.current.start).utc(true);
    }
    else {
      let start = moment(termin_lkp.current.startDate)
      start.set({ hour: parseInt(termin_lkp.current.startTime.substring(0,2), 10), minute: parseInt(termin_lkp.current.startTime.substring(3,5), 10) })

      let end = moment(termin_lkp.current.endDate)
      end.set({ hour: parseInt(termin_lkp.current.endTime.substring(0,2), 10), minute: parseInt(termin_lkp.current.endTime.substring(3,5), 10) })

      duration = end - start;
    }

    const s = moment(termin.start).utc(true);
    const e = moment(s).add(duration, "milliseconds");

    termin.obj = JSON.parse(JSON.stringify(termin_lkp.current));
    termin.obj.start = s.format("YYYY-MM-DDTHH:mm");
    termin.obj.end = e.format("YYYY-MM-DDTHH:mm");
    termin.obj.category = termin.mitarbeiter_str;
    termin.obj.status = "Temporär";

    state.termin_move.termin.new = termin;
  },



  addAppointmentsFromSeries(state, data) {
    // Remove existing termine
    state.add.selected.termine = [];

    // Reset unique_id and termin_key
    state.add.selected.termin_key = 0;

    // Prepare treatment buckets
    let treatments = [];
    for (const entry of state.add.selected.behandlungen) {
      treatments.push([entry.anzahl, entry]);
    }

    const patientId = this.getters["overlays/getPatientId"]

    for (const element of data) {
      let employee = this.getters["mitarbeiter/getMitarbeiterbyId"](element.employeeId)
      let employeeStr = employee.name + ", " + employee.vorname;

      let s = moment(element.start).utc(true);
      let e = moment(element.end).utc(true);

      let termin = {
        key: state.add.selected.termin_key,
        id: -1 * (state.add.selected.termine.length + 1),

        start_date: s,
        start_date_str: s.format("DD.MM.YYYY"),
        start_time: s.format("HH:mm"),

        end_date: e,
        end_time: e.format("HH:mm"),

        mitarbeiter: null,
        mitarbeiter_str: "-",
        patient: patientId,

        status: "Temporär",
        valid: element.valid,
        error_message: element.errors.join("\n"),

        available_mitarbeiter: element.availableEmployees,
        behandlungen: [],
      };

      if (element.valid) {
        termin.mitarbeiter = employee;
        termin.mitarbeiter_str = employeeStr;
      }

      // Set Treatments
      for (const i in treatments) {
        let [count, treatment] = treatments[i];

        if (count > 0) {
          termin.behandlungen.push(treatment);
          treatments[i][0] -= 1;
        }
      }

      // Update termin_key
      state.add.selected.termin_key += 1;

      // Push termin
      state.add.selected.termine.push(termin);

    }

    // Sort termine by start_date
    this.commit("overlays/orderTermine");

    // Reset all temporary appointments
    this.commit("termine/removeTemporaryAppointments", {}, { root: true });

    // Add all termine to temporary appointments
    this.commit("termine/addTemporaryAppointments", state.add.selected.termine, { root: true });
  },

  addTerminToList(state, data) {
    let mod = state.add.active ? state.add : state.edit;

    const patientId = this.getters["overlays/getPatientId"]
    const entryId = (mod.selected.termine.length + 1) * -1;

    let termin = {
      key: state.add.selected.termin_key,
      id: entryId,
      start_date: moment(data.date).hour(data.start_str.substring(0, 2)).minute(data.start_str.substring(3)),
      start_date_str: null,
      start_time: data.start_str,
      end_date: null,
      end_time: null,
      mitarbeiter: null,
      mitarbeiter_str: "-",
      status: data.status == "Erledigt" ? data.status : "Temporär",
      valid: data.valid,
      error_message: "",
      available_mitarbeiter: data.available_mitarbeiter,
      behandlungen: [],
      patient: patientId,
    };


    // Create start_date_str for display value
    termin.start_date_str = termin.start_date.format("DD.MM.YYYY");

    let duration = 0;
    for (const b of mod.selected.behandlungen_selected) {
      termin.behandlungen.push(b);
      duration += parseInt(b.zeiteinheit, 10);
    }

    // Set end date and time
    termin.end_date = moment(termin.start_date);
    termin.end_date.add(duration, "m");
    termin.end_time = termin.end_date.format("HH:mm");

    // Add mitarbeiter info if present in data object
    if ("mitarbeiter" in data && data.mitarbeiter != null) {
      termin.mitarbeiter = data.mitarbeiter;
      termin.mitarbeiter_str = data.mitarbeiter.name + ", " + data.mitarbeiter.vorname;
    } else {
      termin.valid = false;
      termin.error_message = "Keinen Mitarbeiter ausgewählt!";
    }

    // Update termin_key
    mod.selected.termin_key += 1;

    // Push termin
    mod.selected.termine.push(termin);

    // Reset selected
    mod.selected.behandlungen_selected = [];

    // Ensure appointments are sorted by start_date
    this.commit("overlays/orderTermine");

    // Reset all temporary appointments
    this.commit("termine/removeTemporaryAppointments", {}, { root: true });
    
    // Re-add all new appointments as temporary appointments
    this.commit("termine/addTemporaryAppointments", mod.selected.termine, { root: true });

    // Re-enable AddTermin Window
    state.snackbars.addEditSuccess = true;
    mod.visible = true;
    mod.selecting = false;
    state.snackbars.addEditSelect = false;
    state.snackbars.addEditSuccess = true;
  },

  replaceTerminInList(state, data) {
    let mod = state.add.active ? state.add : state.edit;

    let idx = (mod.selected.termine.findIndex((element) => element.id == mod.repick_id));

    // Replace
    mod.selected.termine[idx].start_date = moment(data.date).hour(data.start_str.substring(0, 2)).minute(data.start_str.substring(3));
    mod.selected.termine[idx].start_date_str = mod.selected.termine[idx].start_date.format("DD.MM.YYYY");
    mod.selected.termine[idx].start_time = data.start_str;
    mod.selected.termine[idx].mitarbeiter = null;
    mod.selected.termine[idx].mitarbeiter_str = "-";
    mod.selected.termine[idx].valid = data.valid;
    mod.selected.termine[idx].available_mitarbeiter = data.available_mitarbeiter;

    mod.selected.termine[idx].status = data.status == "Erledigt" ? data.status : "Temporär";

    // Replace behandlungen
    mod.selected.termine[idx].behandlungen = [];
    let duration = 0;
    for (const b of mod.selected.behandlungen_selected) {
      mod.selected.termine[idx].behandlungen.push(b);
      duration += parseInt(b.zeiteinheit, 10);
    }

    // Set end date and time
    mod.selected.termine[idx].end_date = moment(mod.selected.termine[idx].start_date);
    mod.selected.termine[idx].end_date.add(duration, "m");
    mod.selected.termine[idx].end_time = mod.selected.termine[idx].end_date.format("HH:mm");

    // Add mitarbeiter info if present in data object
    if (data.mitarbeiter) {
      mod.selected.termine[idx].mitarbeiter = data.mitarbeiter;
      mod.selected.termine[idx].mitarbeiter_str = data.mitarbeiter.name + ", " + data.mitarbeiter.vorname;
    } else {
      mod.selected.termine[idx].valid = false;
      mod.selected.termine[idx].error_message = "Keinen Mitarbeiter ausgewählt!";
    }

    // Re-enable AddTermin Window
    state.snackbars.addEditSuccess = true;
    mod.visible = true;
    mod.repicking = false;
    state.snackbars.addEditRepick = false;
    state.snackbars.addEditSuccess = true;
    mod.repick_id = null;

    this.commit("overlays/orderTermine");
    this.commit("overlays/recheckTermine");
  },

  async recheckTermine(state) {
    let mod = state.add.active ? state.add : state.edit;

    if (mod.selected.termine.length != 0) {
      // We need to pass all appointments to the backend to check if they are valid
      let appointmentsData = await this.dispatch("overlays/getAppointmentsData", null, { root: true });

      // Add groupId if available
      if(mod.rawData){
        appointmentsData.id = mod.rawData.id;
      }

      let responseData = await this.dispatch("termine/validateAppointments", appointmentsData, { root: true });

      for (let i = 0; i < mod.selected.termine.length; i++) {
        let termin = mod.selected.termine[i];
        let errors = responseData.errors[i];

        termin.available_mitarbeiter = new Set(responseData.employeesAvailability[i]);

        termin.valid = true;

        if (errors) {
          termin.valid = false;
          // Take only the first error message
          termin.error_message = errors[0];  
        }

        if(!termin.mitarbeiter){
          termin.valid = false;
          termin.error_message = "Keinen Mitarbeiter ausgewählt!";
        }
      }

    }

    // Always remove and re-add temporary appointments to ensure that the correct appointments are displayed
    // Easier than updating the appointments in place
    this.commit("termine/removeTemporaryAppointments", {}, { root: true });
    this.commit("termine/addTemporaryAppointments", mod.selected.termine, { root: true });
    
  },

  replaceTreatment(state, treatments) {
    let mod = state.add.active ? state.add : state.edit;

    // Find appointment
    let idx = mod.selected.termine.findIndex((element) => element.id == mod.repick_id);

    // Replace treatments
    mod.selected.termine[idx].behandlungen = treatments;
  },

  orderTermine(state) {
    function termine_compare(termin_a, termin_b) {
      let moment_a = moment(termin_a.start_date);
      let moment_b = moment(termin_b.start_date);

      let [h_a, m_a] = termin_a.start_time.split(":");
      moment_a.hour(h_a);
      moment_a.minute(m_a);

      let [h_b, m_b] = termin_b.start_time.split(":");
      moment_b.hour(h_b);
      moment_b.minute(m_b);

      if (moment_a.isBefore(moment_b)) {
        return -1;
      }
      if (moment_a.isAfter(moment_b)) {
        return 1;
      }
      return 0;
    }

    if (state.add.active) {
      state.add.selected.termine.sort(termine_compare);

      // Update id
      for (let index = 0; index < state.add.selected.termine.length; index++) {
        state.add.selected.termine[index].id = -1 * (index + 1);
        state.add.selected.termine[index].key = index + 1;
      }

    }

    if (state.edit.active) {
      state.edit.selected.termine.sort(termine_compare);

      // Update id
      for (let index = 0; index < state.edit.selected.termine.length; index++) {
        state.edit.selected.termine[index].id = -1 * (index + 1);
        state.edit.selected.termine[index].key = index + 1;
      }

    }
  },

  addHeilmittel(state) {
    let mod = state.add.active ? state.add.selected : state.edit.selected;
    let hm = { ...mod.heilmittel };

    if (hm) {
      // Add key property (only needed for data tables)
      hm.key = mod.behandlungen.length + 1;

      // Add anzahl / remaining property
      hm.anzahl = 1;
      hm.remaining = 1;
      hm.valid = true;

      mod.behandlungen.push(hm);
    }

    this.commit("overlays/recheckTermine");
  },

  removeHeilmittel(state, key) {
    if (state.add.active) {
      let idx = state.add.selected.behandlungen.findIndex((x) => x.key === key);
      state.add.selected.behandlungen.splice(idx, 1);
    }

    if (state.edit.active) {
      let idx = state.edit.selected.behandlungen.findIndex((x) => x.key === key);
      state.edit.selected.behandlungen.splice(idx, 1);
    }
  },

  updateBemerkung(state, value) {
    state.add.selected.bemerkung = value;
  },

  updateTermintyp(state, value) {

    if (state.add.active) {
      state.add.selected.termintyp = value;
    }

    if (state.edit.active) {
      state.edit.selected.termintyp = value;
    }

  },

  updateTreatmentType(state, value) {
    state.add.selected.treatmentType = value;
  },

  updateTerminmode(state, value) {
    if (state.add.active) {
      state.add.selected.terminmode = value;
    }

    if (state.edit.active) {
      state.edit.selected.terminmode = value;
    }
  },

  updatePatientNeu(state, value) {
    state.add.selected.patient_neu = value;
  },

  updatePatient(state, value) {
    state.add.selected.patient = value;
  },

  updateBehandlungen(state, value) {
    if (state.add.active){
      state.add.selected.behandlungen = value;
    }

    if (state.edit.active){
      state.edit.selected.behandlungen = value;
    }
  },

  updateBehandlungenSelected(state, value) {
    if (state.add.active){
      state.add.selected.behandlungen_selected = value;
    }

    if (state.edit.active){
      state.edit.selected.behandlungen_selected = value;
    }
  },

  updateColor(state, value) {
    state.add.selected.color = value;
  },

  updateName(state, value) {
    state.add.selected.name = value;
  },

  updateGanztag(state, value) {
    state.add.selected.ganztag = value;
  },

  updateMitarbeiter(state, value) {
    state.add.selected.mitarbeiter = value;
  },

  updateAddDisabled(state, value) {
    state.add.disabled = value;
  },

  updatePatientNeuName(state, value) {
    state.add.selected.patient_name = value;
  },

  updatePatientNeuVorname(state, value) {
    state.add.selected.patient_vorname = value;
  },

  updatePatientNeuGeburtsdatum(state, value) {
    state.add.selected.patient_geburtsdatum = value;
  },

  updatePatientNeuTelefon(state, value) {
    state.add.selected.patient_telefon = value;
  },

  updateStartDate(state, value) {
    state.add.selected.start_date = value;
  },

  updateEndDate(state, value) {
    state.add.selected.end_date = value;
  },

  updateDragConfirmationVisible(state, value) {
    state.drag_confirmation.visible = value;
  },

  updateStartTime(state, value) {
    state.add.selected.start_time = value;
  },

  updateEndTime(state, value) {
    state.add.selected.end_time = value;
  },

  updatePatientNeuFlag(state, value) {
    state.add.selected.patient_neu_flag = value;
  },

  updateHeilmittel(state, value) {
    if (state.add.active) {
      state.add.selected.heilmittel = value;
    }

    if (state.edit.active) {
      state.edit.selected.heilmittel = value;
    }
  },

  updateSettingsHeilmittel(state, value) {
    state.settings.timeunits.selected.heilmittel = value;
  },

  updateTermine(state, value) {
    if (state.add.active) {
      state.add.selected.termine = value;
    }

    if (state.edit.active) {
      state.edit.selected.termine = value;
    }
  },

  updateSelectSnackbar(state, value) {
    state.snackbars.addEditSelect = value;
  },

  updateTerminMoveSnackbar(state, value) {
    state.termin_move.active = value;
  },

  updateRepickSnackbar(state, value) {
    state.snackbars.addEditRepick = value;
  },

  updateSuccessSnackbar(state, value) {
    state.snackbars.success_select = value;
  },

  updateMitarbeiterBusySnackbar(state, value) {
    state.snackbars.mitarbeiter_busy = value;
  },

  removeTermin(state, key) {
    let mod = state.add.active ? state.add : state.edit;
    let idx = mod.selected.termine.findIndex((x) => x.key === key);
    mod.selected.termine.splice(idx, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
