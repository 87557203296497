<template>
  <v-row justify="center">
    <v-dialog
      v-model="active"
      persistent
      max-width="600px"
      @keydown.esc="closeSelectHeilmittel"
    >
      <v-card>
        <v-card-title>
          <span>Heilmittelauswahl</span>
        </v-card-title>

        <v-card-text style="padding-bottom: 0px">
          <v-container>
            <v-row v-if="repickItem && repickItem.status === 'Erledigt'">
              <v-col>
                <v-alert small type="info" outlined class="mb-4">
                  <p class="mb-1">
                    Der ausgewählte Termin wurde bereits abgeschlossen.
                  </p>
                  <p class="mb-0">
                    Sie können das Heilmittel anpassen, jedoch bleiben die Dauer
                    und der Zeitpunkt unverändert.
                  </p>
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col style="padding-left: 0px; padding-right: 0px">
                <v-data-table
                  dense
                  show-select
                  v-model="behandlungen_selected"
                  :headers="behandlungen_headers"
                  :items="verbleibende_behandlungen"
                  :items-per-page="10"
                  class="elevation-1"
                  :hide-default-footer="true"
                  item-key="key"
                  @click:row="rowClicked"
                  @item-selected="checkSelection"
                >
                  <template v-slot:item.remaining="{ item }">
                    {{ item.remaining }} / {{ item.anzahl }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <p><b> Summe: </b>{{ time_behandlungen }}</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSelectHeilmittel">
            Zurück
          </v-btn>

          <v-btn
            v-if="!repickItem || (repickItem && repickItem.status !== 'Erledigt')"
            :disabled="behandlungen_selected.length == 0"
            class="primary-nav-btn"
            color="primary"
            text
            @click="hideAddEditTerminWithSnackbar"
            >Terminauswahl</v-btn
          >
          <v-btn
          v-if="repickItem && repickItem.status === 'Erledigt'"
          :disabled="behandlungen_selected.length == 0"
          class="primary-nav-btn"
            color="primary"
            text
            @click="adjustCompletedAppointment"
          >
            Anpassen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "SelectHeilmittel",

  data() {
    return {
      behandlungen_headers: [
        {
          text: "Bezeichnung",
          align: "start",
          value: "bezeichnung",
        },
        {
          text: "Verbleibend",
          value: "remaining",
        },
        {
          text: "Dauer",
          value: "zeiteinheit",
        },
      ],
    };
  },
  computed: {
    addIsActive() {
      return this.$store.getters["overlays/addIsActive"];
    },

    editIsActive() {
      return this.$store.getters["overlays/editIsActive"];
    },

    active() {
      if (this.addIsActive) {
        return this.$store.getters["overlays/addSelectHeilmittelIsVisible"];
      } else if (this.editIsActive) {
        return this.$store.getters["overlays/editSelectHeilmittelIsVisible"];
      }

      return false;
    },

    repickItem() {
      return this.$store.getters["overlays/getRepickItem"];
    },

    verbleibende_behandlungen() {
      if (this.addIsActive) {
        return this.$store.getters["overlays/addGetRemainingBehandlungen"];
      } else if (
        this.editIsActive &&
        this.$store.state.overlays.edit.rawData.gruppen_typ == "Behandlung"
      ) {
        return this.$store.getters["overlays/editGetRemainingBehandlungen"];
      }

      return [];
    },

    time_behandlungen() {
      return this.$store.getters["overlays/behandlungenSelectedLength"];
    },

    behandlungen_selected: {
      get() {
        if (this.addIsActive) {
          return this.$store.state.overlays.add.selected.behandlungen_selected;
        } else if (
          this.editIsActive &&
          this.$store.state.overlays.edit.rawData.gruppen_typ == "Behandlung"
        ) {
          return this.$store.state.overlays.edit.selected.behandlungen_selected;
        }

        return [];
      },

      set(value) {
        this.$store.commit("overlays/updateBehandlungenSelected", value, {
          root: true,
        });
      },
    },
  },
  methods: {
    ...mapMutations("overlays", [
      "closeSelectHeilmittel",
      "hideAddEditTerminWithSnackbar",
      "replaceTreatment",
    ]),

    rowClicked(d1, d2) {
      if (d2.isSelected) {
        d2.select(false);
      } else {
        d2.select(true);
      }
    },

    adjustCompletedAppointment() {
      this.replaceTreatment(this.behandlungen_selected);
      this.closeSelectHeilmittel();
    },

    checkSelection(selection) {
      const { item, value } = selection;

      if (value) {
        // item was selected
        // check if there is another item remaining that has the same name but different key and add it also as selected
        const relevant_selected = this.verbleibende_behandlungen.filter(
          (beh) => beh.bezeichnung == item.bezeichnung
        );
        this.behandlungen_selected = this.behandlungen_selected.concat(
          relevant_selected
        );
      } else {
        // item was deselected, deselect any other item that has the same name
        this.behandlungen_selected = this.behandlungen_selected.filter(
          (beh) => beh.bezeichnung != item.bezeichnung
        );
      }
    },
  },
  watch: {},
};
</script>

<style></style>
