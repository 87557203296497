<template>
  <div>
    <v-navigation-drawer clipped app v-model="drawer">
      <div class="forbiddenDrop" style="height: 298px;">
        <v-date-picker
          no-title
          width="255"
          locale="DE-de"
          color="primary"
          v-model="calendar.now"
          first-day-of-week="1"
        >
        </v-date-picker>
      </div>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          link
          @click="openPatientenOverview"
          :disabled="moveIsActive || addIsActive || editIsActive"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Patienten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="openAddPatient(null)"
          :disabled="moveIsActive || addIsActive || editIsActive"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Neuer Patient</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="openAddDoctor(null)" :disabled="moveIsActive || addIsActive || editIsActive">
          <v-list-item-icon>
            <v-icon>$doctoradd</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Neuer Arzt</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :disabled="moveIsActive || addIsActive || editIsActive">
          <v-list-item-icon>
            <v-icon>mdi-text-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content @click="openPrescriptionOverview">
            <v-list-item-title>Aktuelle Rezepte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :disabled="moveIsActive || addIsActive || editIsActive">
          <v-list-item-icon>
            <v-icon>mdi-notebook-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-content @click="openAddTermin">
            <v-list-item-title>Terminerstellung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="openNacherfassung" :disabled="moveIsActive || addIsActive || editIsActive">
          <v-list-item-icon>
            <v-icon>mdi-notebook-edit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Nacherfassung</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="openSettings" :disabled="moveIsActive || addIsActive || editIsActive">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" block @click="logout"> Logout </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app clipped-left flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-img
        style="margin-left: 150px"
        contain
        max-height="30"
        max-width="250"
        src="@/assets/logo.png"
      ></v-img>
      <v-spacer></v-spacer>

      <v-btn class="ml-2" @click="updateTimerAndData" elevation="2" outlined>
        {{ timeLeft }}
        <v-icon right> mdi-refresh </v-icon>
      </v-btn>

      <v-btn
        icon
        width="auto"
        height="auto"
        class="ml-4 elevation-2"
        style="border: 1px solid black;"
        :disabled="moveIsActive || addIsActive || editIsActive"
      >
        <v-avatar color="white" size="34" @click="openSettings">
          <img v-if="logoUrl" :src="logoUrl" />
          <v-icon v-if="!logoUrl" dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </v-btn>

      <v-btn class="ml-2" @click="logout" depressed icon small>
        <v-icon> mdi-logout-variant </v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid v-resize="onResize">
        <v-row>
          <v-toolbar flat>
            <v-btn @click="setToday">Heute </v-btn>

            <v-btn
              class="ml-4"
              style="height: 36px; width: 36px"
              icon
              medium
              elevation="2"
              @click="$refs.calendar.prev()"
            >
              <v-icon dark> mdi-chevron-left </v-icon>
            </v-btn>

            <v-btn
              style="height: 36px; width: 36px"
              class="ml-4"
              icon
              medium
              elevation="2"
              @click="$refs.calendar.next()"
            >
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>

            <v-toolbar-title class="ml-4" v-if="calendar.loaded">
              {{ $refs.calendar.title }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-select
              dense
              solo
              hide-details
              label="Ansicht"
              style="max-width: 150px"
              :items="calendar.types"
              item-text="label"
              item-value="value"
              v-model="calendarType"
            ></v-select>

            <v-select
              v-model="calendarCategories"
              :items="mitarbeiter"
              :item-value="(item) => item.name + ', ' + item.vorname"
              :item-text="(item) => item.name + ', ' + item.vorname"
              multiple
              hide-details
              solo
              class="ml-4"
              dense
              label="Mitarbeiter"
              style="max-width: 450px"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}, {{ item.vorname }}</span>
                </v-chip>
                <v-chip v-if="index === 1">
                  <span>{{ item.name }}, {{ item.vorname }}</span>
                </v-chip>

                <span
                  v-if="index === 2 && calendarCategories.length - 2 == 1"
                  class="grey--text caption"
                >
                  (+1 weiterer)
                </span>

                <span
                  v-if="index === 2 && calendarCategories.length - 2 != 1"
                  class="grey--text caption"
                >
                  (+{{ calendarCategories.length - 2 }} weitere)
                </span>
              </template>
            </v-select>

            <v-btn
              style="height: 36px; width: 36px"
              icon
              medium
              class="ml-1"
              :disabled="!m_selected"
              @click="clearMitarbeiter"
            >
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
        </v-row>

        <!-- Calendar row -->
        <v-row>
          <v-col>
            <v-card :height="windowSize.y - 180">
              <v-calendar
                v-show="!isLoading"
                locale="DE-de"
                ref="calendar"
                v-model="calendar.now"
                :type="calendarType"
                :first-time="calendar.firsttime"
                :interval-minutes="calendar.interval_minutes"
                :interval-count="calendar.interval_count"
                :weekdays="calendar.weekdays"
                :categories="calendarCategories"
                :locale-first-day-of-year="calendar.localeFirstDayOfYear"
                :category-show-all="true"
                :show-week="true"
                event-more-text="{0} weitere Termine"
                @click:more="viewDay"
                @click:date="viewDay"
                :short-intervals="false"
                :events="events_filtered"
                @click:event="showEvent"
                @click:time="addTerminFromClick"
                @click:time-category="addTerminFromClick"
                @mousedown:event="startDrag"
                @mousedown:time="startTime"
                @mousemove:time="mouseMove"
                @mousemove:time-category="mouseMove"
                @mouseup:time="endDrag"
                :interval-height="calendar.interval_height"
                :event-color="getLighterEventColor"
                event-text-color="black"
                @change="updateAppointments"
              >
                <template
                  v-slot:day-body="{ date, week, category, timeToY, weekday }"
                >
                  <v-overlay
                    absolute
                    v-if="calendarType == 'category' && !m_selected"
                  >
                    <v-alert class="overlay-alert" type="info" color="primary">
                      Es wurde kein <strong>Mitarbeiter</strong> ausgewählt.
                    </v-alert>
                  </v-overlay>
                  
                  <!-- Unavailable times overlay - Category view -->
                  <template v-if="calendarType == 'category' && category">
                    <div v-if="!isLoading && !isHoliday(date)">
                      <div
                        v-for="entry in getUnavailableTimes(category, weekday)"
                        :key="entry.index"
                        class="v-unavailable-hours"
                        :style="getTopHeight(entry, timeToY)"
                      >
                        <div class="v-unavailable-hours-text">Abwesend</div>
                      </div>
                    </div>
                  </template>

                  <!-- Unavailable times overlay - Daily and Weekly view -->
                  <template v-if="calendarCategories.length == 1">
                    <div v-if="['day', 'week'].includes(calendarType)">
                      <div
                        v-for="entry in getUnavailableTimes(calendarCategories[0], weekday)"
                        :key="entry.index"
                        class="v-unavailable-hours"
                        :style="getTopHeight(entry, timeToY)"
                      >
                        <div class="v-unavailable-hours-text">Abwesend</div>
                      </div>
                    </div>
                  </template>

                  <!-- Holidays overlay -->
                  <div v-if="!isLoading && isHoliday(date)">
                    <div class="v-holidays" :style="getTotalTopHeight(timeToY)">
                      <div class="v-holidays-text">
                        {{ getHoliday(date).name }}
                      </div>
                    </div>
                  </div>

                  <!-- Current time line -->
                  <div
                    v-if="!isLoading && isToday(date)"
                    class="v-current-time"
                    :class="{
                      first:
                        date === week[0].date &&
                        (!category || category == calendarCategories[0]),
                    }"
                    :style="{ top: nowY(timeToY) }"
                  >
                    <v-chip
                      v-if="!category || category == calendarCategories[0]"
                      class="px-1 v-current-time-chip"
                      pill
                      x-small
                    >
                      {{ getCurrentTimeHHmm() }}
                    </v-chip>
                  </div>
                </template>

                <template v-slot:event="{ event, timed, timeSummary }">
                  <div class="v-event-draggable pl-3 event-summary">
                    <div class="summaryEntry" style="font-size: 13px; font-weight: bold !important">
                      <span
                        v-if="
                          event.termintyp == 'Behandlung' &&
                            event.patient_data &&
                            event.patient_data.name
                        "
                      >
                        {{ event.patient_data.name }} -
                      </span>
                      <span class="summaryEntry">{{ event.name }}</span>
                    </div>
                    <div>{{ timeSummary() }}</div>
                  </div>
                  <div
                    v-if="
                      timed &&
                        event.status == 'Offen' &&
                        !moveIsActive &&
                        !select_snackbar &&
                        !repick_snackbar &&
                        !dragEvent
                    "
                    class="v-event-drag-bottom"
                    @mousedown.left.stop="extendBottom(event)"
                  ></div>

                  <div
                    class="event-border"
                    :class="getEventBorderClasses(event)"
                    :style="getEventBorderStyle(event)"
                  ></div>
                </template>
              </v-calendar>

              <v-menu
                class="event-menu"
                v-model="calendar.selectedOpen"
                :close-on-content-click="false"
                min-width="400px"
                content-class="event-menu"
                :position-x="calendar.selected_x"
                :position-y="calendar.selected_y"
              >
                <v-card color="grey lighten-4" flat>
                  <v-toolbar
                    :color="getEventColor(calendar.selectedEvent)"
                    dark
                    extended
                    style="box-shadow:unset !important"
                  >
                    <v-toolbar-title>
                      <span
                        v-if="
                          calendar.selectedEvent.termintyp == 'Behandlung' &&
                            calendar.selectedEvent.patient_data
                        "
                      >
                        {{ calendar.selectedEvent.patient_data.name }} -
                      </span>
                      <span>{{ calendar.selectedEvent.name }}</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn icon @click="closeTerminPreview">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <template v-slot:extension>
                      <div>
                        <p
                          v-if="
                            calendar.selectedEvent.termintyp == 'Behandlung'
                          "
                        >
                          Status: {{ calendar.selectedEvent.status }}
                        </p>

                        <p v-if="calendar.selectedEvent.termintyp == 'Intern'">
                          Interner Termin
                        </p>

                        <div
                          v-if="
                            calendar.selectedEvent.termintyp == 'Behandlung'
                          "
                        >
                          <v-btn-toggle
                            :max="0"
                            multiple
                            :value="[]"
                            dense
                            light
                            rounded
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="moveTermin"
                                  :disabled="
                                    calendar.selectedEvent.status == 'Erledigt'
                                  "
                                  style="border-color: black !important;"
                                >
                                  <v-icon>mdi-update</v-icon>
                                </v-btn>
                              </template>
                              <span>Verschieben</span>
                            </v-tooltip>


                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="
                                    calendar.selectedEvent.groupStatus != 'Offen' && calendar.selectedEvent.groupTransferred 
                                  "
                                  @click="editAppointment"
                                  style="border-color: black !important;"
                                >
                                  <v-icon>mdi-calendar-edit</v-icon>
                                </v-btn>
                              </template>
                              <span>Editierung</span>
                            </v-tooltip>


                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="createTerminZettel"
                                  style="border-color: black !important;"
                                  :disabled="calendar.selectedEvent.group_with_invalid_appointments"
                                >
                                  <v-icon>mdi-calendar-export</v-icon>
                                </v-btn>
                              </template>
                              <span>Terminzettel</span>
                            </v-tooltip>

                            <v-tooltip
                              v-if="
                                !isFutureAppointment(calendar.selectedEvent) &&
                                  !rejectDialDisabled
                              "
                              bottom
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="green"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="confirmTermin"
                                  :disabled="
                                    isFutureAppointment(calendar.selectedEvent)
                                  "
                                  style="border-color: black !important;"
                                >
                                  <v-icon style="color: white"
                                    >mdi-calendar-check</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Bestätigen</span>
                            </v-tooltip>

                            <v-tooltip v-if="!rejectDialDisabled" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="rejectTermin"
                                  style="border-color: black !important;"
                                >
                                  <v-icon style="color: white; "
                                    >mdi-calendar-remove</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Absagen</span>
                            </v-tooltip>

                            <v-tooltip v-if="rejectDialDisabled && calendar.selectedEvent.status == 'Abgesagt'" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="openDeleteDialog"
                                  style="border-color: black !important;"
                                >
                                  <v-icon style="color: white;"
                                    >mdi-trash-can</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Löschen</span>
                            </v-tooltip>
                          </v-btn-toggle>
                        </div>

                        <div
                          v-if="calendar.selectedEvent.termintyp == 'Intern'"
                        >
                          <v-btn-toggle
                            :max="0"
                            multiple
                            :value="[]"
                            dense
                            light
                            rounded
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="
                                    eventEditDisabled(calendar.selectedEvent)
                                  "
                                  @click="editAppointment"
                                  style="border-color: black !important;"
                                >
                                  <v-icon>mdi-calendar-edit</v-icon>
                                </v-btn>
                              </template>
                              <span>Editierung</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="red"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="openDeleteDialog"
                                  style="border-color: black !important;"
                                >
                                  <v-icon style="color: white;"
                                    >mdi-trash-can</v-icon
                                  >
                                </v-btn>
                              </template>
                              <span>Löschen</span>
                            </v-tooltip>
                          </v-btn-toggle>
                        </div>
                      </div>
                    </template>
                  </v-toolbar>

                  <v-card-text style="padding-top: 40px">
                    <v-row justify="center">
                      <v-col cols="2" align="center">
                        <v-icon class="entry-icon">mdi-clock-outline</v-icon>
                      </v-col>
                      <v-col cols="10">
                        <p class="event-entry">{{ get_date_info.header }}</p>
                        <p class="event-subentry">{{ get_date_info.line }}</p>
                      </v-col>
                    </v-row>

                    <v-row
                      justify="center"
                      v-if="get_behandlungen_info.lines.length != 0"
                    >
                      <v-col cols="2" align="center">
                        <v-icon class="entry-icon">mdi-hand-heart</v-icon>
                      </v-col>
                      <v-col cols="10">
                        <p class="event-entry">
                          {{ get_behandlungen_info.header }}
                        </p>

                        <p
                          class="event-subentry"
                          v-for="item in get_behandlungen_info.lines"
                          :key="item.index"
                        >
                          {{ item }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-row justify="center" v-if="get_patienten_info.valid">
                      <v-col cols="2" align="center">
                        <v-icon class="entry-icon">mdi-account</v-icon>
                      </v-col>
                      <v-col cols="10">
                        <p class="event-entry">{{ get_patienten_info.name }}</p>
                        <p
                          class="event-subentry"
                          v-if="get_patienten_info.geburtsdatum"
                        >
                          geb. {{ get_patienten_info.geburtsdatum }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-row justify="center">
                      <v-col cols="2" align="center">
                        <v-icon class="entry-icon">mdi-doctor</v-icon>
                      </v-col>
                      <v-col cols="10">
                        <p class="event-entry">{{ get_mitarbeiter_info }}</p>
                      </v-col>
                    </v-row>

                    <v-row justify="center" v-if="get_bemerkung_info">
                      <v-col cols="2" align="center">
                        <v-icon class="entry-icon">mdi-comment</v-icon>
                      </v-col>
                      <v-col cols="10">
                        <p class="event-entry">{{ get_bemerkung_info }}</p>
                      </v-col>
                    </v-row>

                    <v-row v-if="calendar.selectedEvent.group_with_invalid_appointments">
                      <v-col cols="12" align="center">
                        <div style="font-size: 12px;">
                           <strong>Hinweis:</strong> Terminegruppe enthält ungültige Termine!
                        </div>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-menu>
            </v-card>
          </v-col>
        </v-row>

        <v-btn
          color="primary"
          absolute
          bottom
          right
          style="bottom: 10px; z-index: 101; margin-right: 20px;"
          @click="openAddTermin"
          :disabled="
            addIsActive ||
            editIsActive ||
            moveIsActive ||
            settingsIsActive ||
            isLoading ||
            nacherfassungIsActive
          "
        >
          <v-icon>mdi-plus</v-icon>
          Neuer Termin
        </v-btn>
        <v-row style="margin-top: -16px; display: flex; position: relative;">
          <v-col class="d-flex" style="padding-bottom: 0px">
            <div style="display: flex; " class="mr-5">
              <span class="colorbox offen"></span
              ><span class="colorboxtext">Offen</span>
            </div>

            <div style="display: flex;" class="mr-5">
              <span class="colorbox erledigt"></span
              ><span class="colorboxtext">Erledigt</span>
            </div>

            <div style="display: flex;" class="mr-5">
              <span class="colorbox abgesagt"></span
              ><span class="colorboxtext">Abgesagt</span>
            </div>

            <div style="display: flex;" class="mr-5">
              <span class="colorbox ungueltig"></span
              ><span class="colorboxtext">Ungültig</span>
            </div>

            <div style="display: flex;" class="mr-5">
              <span class="colorbox feiertag"></span
              ><span class="colorboxtext">Feiertag</span>
            </div>

            <div style="display: flex;" class="mr-5">
              <span class="colorbox temp"></span
              ><span class="colorboxtext">Temporär</span>
            </div>
          </v-col>
        </v-row>

        <TerminAdd :switchToTermin="switchToTermin"/>
        <TerminEdit :switchToTermin="switchToTermin" />
        <SelectHeilmittel />
        <PatientAdd />
        <DoctorAdd />
        <DragConfirmation />
        <PatientenOverview />
        <PatientTermine :switchToTermin="switchToTermin" />
        <Nacherfassung :switchToTermin="switchToTermin" />
        <Rezept />
        <TimeOut :updateTimer="updateTimer" />
        <TerminReject />
        <TerminMove />
        <Settings />
        <PrescriptionOverview />
        <ExcessPaymentNotification />
        <UnmatchedTarifeNotification />
        <TarifeNacherfassung />

        <v-snackbar v-model="select_snackbar" timeout="-1">
          Bitte Termin wählen

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="showAddEditTerminWithSnackbarStatus('abort')"
            >
              Zurück
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar v-model="repick_snackbar" timeout="-1">
          Bitte neuen Termin wählen

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="showAddEditTerminWithSnackbarStatus('abort')"
            >
              Zurück
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="success_snackbar"
          timeout="2500"
          dark
          color="green"
          style="text-align: center"
        >
          Termin erfolgreich ausgewählt!

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="showAddEditTerminWithSnackbarStatus('abort')"
            >
              Verstanden
            </v-btn>
          </template>
        </v-snackbar>

        <v-snackbar
          v-model="mitarbeiter_busy_snackbar"
          timeout="2500"
          dark
          color="red"
          :class="{
            m_higher: moveIsActive,
            higher: select_snackbar || repick_snackbar,
          }"
          style="text-align: center; font-size: 1rem;"
        >
          {{ busy_message }}
        </v-snackbar>

        <v-dialog v-model="deleteDialog" persistent max-width="450px">
          <v-card light>
            <v-card-title style="background-color: #f5f5f5">
              <span>Termin löschen</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDeleteDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <span>Möchten Sie diesen Termin wirklich löschen?</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDeleteDialog">
                Abbrechen
              </v-btn>
              <v-btn
                class="primary-nav-btn"
                color="primary"
                text
                @click="deleteTermin"
              >
                Löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="invalidAppointmentsDialog"
          persistent
          max-width="500px"
        >
          <v-card light>
            <v-card-title style="background-color: #d47910; color: white;">
              <v-btn icon class="mr-2">
                <v-icon color="white">mdi-alert</v-icon>
              </v-btn>
              <span> Ungültige Termine vorhanden</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <div class="pb-3 pt-2">
                      <span v-if="invalidAppointmentsCount == 1">
                        Es existiert <strong>1</strong> ungültiger Termin.</span
                      >
                      <span v-else
                        >Es existieren
                        <strong>{{ invalidAppointmentsCount }}</strong>
                        ungültige Termine.</span
                      >
                    </div>

                    <div class="pb-4">
                      <span
                        >Für Termingruppen mit ungültigen Terminen ist es nicht
                        möglich einen Terminzettel zu generieren. Diese
                        Termingruppen können ebenfalls nicht abgeschlossen und
                        abgerechnet werden.
                      </span>
                    </div>

                    <div>
                      <span v-if="invalidAppointmentsCount == 1" style="font-weight: 600;">Möchten Sie diesen Eintrag jetzt bearbeiten?</span>
                      <span v-else style="font-weight: 600;">Möchten Sie diese Einträge jetzt bearbeiten?</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeInvalidAppointmentsDialog"
              >
                Später
              </v-btn>
              <v-btn
                class="primary-nav-btn"
                color="primary"
                text
                @click="closeDialogAndOpenNacherfassung"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>

    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment";
import TerminAdd from "@/components/TerminAdd.vue";
import TerminEdit from "@/components/TerminEdit.vue";
import PatientAdd from "@/components/PatientAdd.vue";
import DoctorAdd from "@/components/DoctorAdd.vue";
import DragConfirmation from "@/components/DragConfirmation.vue";
import PatientenOverview from "@/components/PatientenOverview.vue";
import PatientTermine from "@/components/PatientTermine.vue";
import SelectHeilmittel from "@/components/SelectHeilmittel.vue";
import Nacherfassung from "@/components/Nacherfassung.vue";
import Rezept from "@/components/Rezept.vue";
import TimeOut from "@/components/TimeOut.vue";
import TerminReject from "@/components/TerminReject.vue";
import Settings from "@/components/Settings.vue";
import { mapActions, mapMutations } from "vuex";
import TerminMove from "@/components/TerminMove.vue";
import PrescriptionOverview from "@/components/PrescriptionOverview.vue";
import ExcessPaymentNotification from "@/components/ExcessPaymentNotification.vue";
import UnmatchedTarifeNotification from "@/components/UnmatchedTarifeNotification.vue";
import TarifeNacherfassung from "@/components/TarifeNacherfassung.vue";
export default {
  name: "Main",
  metaInfo: {
    title: "medisum Termine",
  },

  components: {
    TerminAdd,
    TerminEdit,
    SelectHeilmittel,
    PatientAdd,
    DoctorAdd,
    DragConfirmation,
    PatientenOverview,
    PatientTermine,
    Nacherfassung,
    Rezept,
    TimeOut,
    TerminReject,
    TerminMove,
    Settings,
    PrescriptionOverview,
    ExcessPaymentNotification,
    UnmatchedTarifeNotification,
    TarifeNacherfassung,
  },
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      busy_message: "",
      isLoading: false,

      disabled_status: ["Abgesagt", "Erledigt"],

      calendar: {
        loaded: false,
        type: "day",
        types: [
          { id: "1", label: "Tag", value: "day" },
          { id: "3", label: "Woche", value: "week" },
          { id: "4", label: "Monat", value: "month" },
          { id: "5", label: "Mitarbeiter", value: "category" },
        ],
        weekdays: [1, 2, 3, 4, 5, 6, 0],
        now: "",
        firsttime: "00:00",
        interval_minutes: 15,
        interval_count: 96,
        interval_height: 50,
        categories: [],
        selectedEvent: {},
        selectedOpen: false,
        selected_x: 0,
        selected_y: 0,
        localeFirstDayOfYear: 4,
      },

      extendStarted: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,

      deleteDialog: false,
      invalidAppointmentsDialog: false,
      invalidAppointmentsCount: 0,

      timeoutShown: false,
    };
  },

  computed: {
    logoUrl: {
      get() {
        return this.$store.getters["settings/getLogoUrl"];
      },
    },

    drawer: {
      get() {
        return this.$store.getters["hauptmenu/getDrawerStatus"];
      },

      set(value) {
        this.$store.commit("hauptmenu/setDrawerStatus", value, { root: true });
      },
    },

    calendarType: {
      get() {
        return this.$store.getters["hauptmenu/getCalendarType"];
      },

      set(value) {
        this.$store.commit("hauptmenu/setCalendarType", value, { root: true });
      },
    },

    calendarCategories: {
      get() {
        return this.$store.getters["hauptmenu/getCalendarCategories"];
      },

      set(value) {
        this.$store.commit("hauptmenu/setCalendarCategories", value, {
          root: true,
        });
      },
    },

    rejectDialDisabled() {
      return this.disabled_status.includes(this.calendar.selectedEvent.status);
    },

    lastTokenRefresh() {
      return this.$store.getters["auth/getLastTokenRefresh"];
    },

    addIsActive() {
      return this.$store.getters["overlays/addIsActive"];
    },

    editIsActive() {
      return this.$store.getters["overlays/editIsActive"];
    },

    nacherfassungIsActive() {
      return this.$store.getters["overlays/nacherfassungActive"];
    },

    moveIsActive() {
      return this.$store.getters["overlays/moveIsActive"];
    },

    settingsIsActive() {
      return this.$store.getters["overlays/settingsActive"];
    },

    mitarbeiterUnavailableTimes() {
      return this.$store.getters["mitarbeiterarbeitszeiten/getAbwesend"];
    },

    termine() {
      return this.$store.getters["termine/getTermine"];
    },

    timeSlotResolution() {
      return this.$store.getters["settings/getTimeSlotResolution"];
    },

    timeSlotHeight(){
      return this.$store.getters["settings/getTimeSlotHeight"];
    },

    select_snackbar: {
      get() {
        return this.$store.state.overlays.snackbars.addEditSelect;
      },

      set(value) {
        this.$store.commit("overlays/updateSelectSnackbar", value, {
          root: true,
        });
      },
    },

    repick_snackbar: {
      get() {
        return this.$store.state.overlays.snackbars.addEditRepick;
      },

      set(value) {
        this.$store.commit("overlays/updateRepickSnackbar", value, {
          root: true,
        });
      },
    },

    success_snackbar: {
      get() {
        return this.$store.state.overlays.snackbars.addEditSuccess;
      },

      set(value) {
        this.$store.commit("overlays/updateSuccessSnackbar", value, {
          root: true,
        });
      },
    },

    mitarbeiter_busy_snackbar: {
      get() {
        return this.$store.state.overlays.snackbars.mitarbeiter_busy;
      },

      set(value) {
        this.$store.commit("overlays/updateMitarbeiterBusySnackbar", value, {
          root: true,
        });
      },
    },

    mitarbeiter() {
      return this.$store.getters["mitarbeiter/getMitarbeiter"];
    },

    m_selected() {
      return this.calendarCategories.length != 0;
    },

    cal() {
      return this.calendar.loaded ? this.$refs.calendar : null;
    },

    timerInterval: {
      get() {
        return this.$store.state.auth.timer.interval;
      },

      set(value) {
        this.$store.commit("auth/updateTimerInterval", value, {
          root: true,
        });
      },
    },

    timerValue: {
      get() {
        return this.$store.state.auth.timer.value;
      },

      set(value) {
        this.$store.commit("auth/updateTimerValue", value, {
          root: true,
        });
      },
    },

    timeLeft() {
      return this.$store.getters["auth/getTimeLeftStr"];
    },

    timerStart() {
      return this.$store.getters["auth/getTimerStart"];
    },

    get_patienten_info() {
      var data = {
        valid: false,
        name: "",
        geburtsdatum: "",
      };
      if (this.calendar.loaded && this.calendar.selectedOpen) {
        var d = this.calendar.selectedEvent.patient_data;
        if (d) {
          data.valid = true;
          data.name = d.name;
          data.geburtsdatum = d.geburtsdatum;
        }
      }
      return data;
    },

    get_date_info() {
      var data = {
        header: "",
        line: "",
      };

      if (this.calendar.loaded && this.calendar.selectedOpen) {
        if (
          this.calendar.selectedEvent.start &&
          this.calendar.selectedEvent.end
        ) {
          // Set locale to german
          moment.locale("de");

          // Get start and end moment objects
          let sm = moment(this.calendar.selectedEvent.start);
          let em = moment(this.calendar.selectedEvent.end);

          if (sm.isSame(em, "date")) {
            data.header = sm.format("dddd, D MMMM YYYY");
            data.line = sm.format("HH:mm") + " - " + em.format("HH:mm");
          } else {
            if (sm.isSame(em, "month")) {
              data.header =
                sm.format("D") +
                " - " +
                em.format("D") +
                sm.format(" MMMM YYYY");
            } else {
              if (sm.isSame(em, "year")) {
                data.header =
                  sm.format("D MMMM") +
                  " - " +
                  em.format("D MMMM") +
                  sm.format(" YYYY");
              } else {
                data.header =
                  sm.format("D MMMM YYYY") + " - " + em.format("D MMMM YYYY");
              }
            }

            data.line =
              sm.format("HH:mm (DD.MM)") + " - " + em.format("HH:mm (DD.MM)");
          }

          // Replace line with "Ganztag" if event is all day
          if (this.calendar.selectedEvent.allDay) {
            data.line = "Ganztag";
          }
        }
      }

      return data;
    },

    get_behandlungen_info() {
      var data = {
        header: "",
        lines: [],
      };

      if (this.calendar.loaded && this.calendar.selectedOpen) {
        if (this.calendar.selectedEvent.behandlungen) {
          var cnt = 0;
          var sum = 0;
          for (
            var i = 0;
            i < this.calendar.selectedEvent.behandlungen.length;
            i++
          ) {
            var entry = this.calendar.selectedEvent.behandlungen[i];
            cnt += 1;
            sum += parseInt(entry[1], 10);

            data.lines.push(
              i + 1 + ". " + entry[0] + " (" + entry[1] + " min)"
            );
          }

          if (cnt >= 2) {
            data.header = cnt + " Behandlungen - " + sum + " min";
          } else {
            data.header = cnt + " Behandlung - " + sum + " min";
          }
        }
      }

      return data;
    },

    get_mitarbeiter_info() {
      var data = "";
      if (this.calendar.loaded && this.calendar.selectedOpen) {
        data = this.calendar.selectedEvent.category;
      }
      return data;
    },

    get_bemerkung_info() {
      var data = "";
      if (this.calendar.loaded && this.calendar.selectedOpen) {
        data = this.calendar.selectedEvent.bemerkung;
      }
      return data;
    },

    events_filtered() {
      if (this.calendar) {
        if (this.calendarCategories.length != 0) {
          var filter = (event) =>
            this.calendarCategories.includes(event.category);
          return this.termine.filter(filter);
        }
      }
      return this.termine;
    },
  },

  mounted() {
    this.onResize();

    window.addEventListener('mouseup', this.handleMouseUp)

    this.$nextTick(() => {
      if (!this.calendar.loaded) {
        this.calendar.loaded = true;
        this.scrollToTime();
        this.updateTime();
      }
    });

    // The following eventBus listener is used to update the token/timer when a request to the backend API is made
    this.$eventBus.$on("requestEvent", () => {
      const currentTime = moment();
      // Only update the token if the last token refresh was more than 40 minutes ago
      if (
        !this.timerStart ||
        currentTime.diff(this.timerStart, "seconds") >= 2400
      ) {
        this.updateTimer();
      }
    });
  },

  watch: {
    calendarType: {
      handler(new_value, old_value) {
        this.scrollToTime();
        if (
          old_value == "month" ||
          new_value == "category" ||
          old_value == "category"
        ) {
          this.$nextTick(() => {
            this.scrollToTime();
          });
        }
      },
    },


    timeSlotResolution: {
      handler(new_value, old_value) {
        
        if (old_value){
          const [interval_minutes, interval_count] = this.getCalendarInterval()
          this.calendar.interval_minutes = interval_minutes;
          this.calendar.interval_count = interval_count;

          setTimeout(() => {
            this.scrollToTime();
          }, 50);


        }

      },
    },

    timeSlotHeight: {
      handler(new_value, old_value) {
        
        if (old_value){
          this.calendar.interval_height = new_value;

          setTimeout(() => {
            this.scrollToTime();
          }, 50);

        }

      },
    },


  },

  methods: {
    ...mapMutations("overlays", [
      "openAddTermin",
      "addTerminToList",
      "replaceTerminInList",
      "showAddEditTerminWithSnackbarStatus",
      "openAddPatient",
      "openAddDoctor",
      "openPatientenOverview",
      "openTimeout",
      "openTerminReject",
      "addMovedTermin",
      "openTerminMove",
      "openPrescriptionOverview",
      "openExcessPaymentNotification",
    ]),

    ...mapMutations(["decTimer"]),

    ...mapActions("overlays", [
      "openNacherfassung",
      "setTerminRejectId",
      "openAndLoadAppointmentEdit",
      "openAndLoadDragConfirmation",
      "handleMovedTermin",
      "openAndLoadSettings",
      "isAppointmentAvailable",
    ]),

    ...mapActions(["GetApplicationData", "ClearApplicationData"]),

    ...mapActions("termine", [
      "updateTerminStatus",
      "getTerminZettel",
      "deleteAppointment",
      "getAppointmentData",
      "checkInvalidAppointments",
      "validateAppointment",
    ]),

    ...mapActions("auth", ["LogOut", "RefreshToken"]),
    ...mapMutations("auth", ["resetTimer"]),

    async updateAppointments({ start }) {
      let type = this.calendarType == "category" ? "day" : this.calendarType;

      if (type == "month") {
        this.isLoading = true;
      }

      // INFO: For now switch timeUnit to "month" to avoid too many requests
      // TODO: Improve UX so that appointments do not pop in when switching pages
      await this.getAppointmentData({ date: start.date, timeUnit: "month" });
      this.isLoading = false;
    },

    isToday(date) {
      return moment(date).isSame(moment(), "day");
    },

    isFutureAppointment(event) {
      if (event) {
        const end = moment(event.end);
        const now = moment();
        return end.isAfter(now);
      }
      return false;
    },

    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },

    componentToHex(c) {
      var hex = c.toString(16);
      return hex.length == 1 ? "0" + hex : hex;
    },

    rgbToHex(r, g, b) {
      return (
        "#" +
        this.componentToHex(r) +
        this.componentToHex(g) +
        this.componentToHex(b)
      );
    },

    blendWhite(rgb, alphaValue) {
      let white = this.hexToRgb("#ffffff");

      const alpha = 1 - alphaValue;

      let r = Math.round(
        (alphaValue * (rgb.r / 255) + alpha * (white.r / 255)) * 255
      );
      let g = Math.round(
        (alphaValue * (rgb.g / 255) + alpha * (white.g / 255)) * 255
      );
      let b = Math.round(
        (alphaValue * (rgb.b / 255) + alpha * (white.b / 255)) * 255
      );

      return { r: r, g: g, b: b };
    },

    getEventColor(event) {
      let color = "#123ffa";
      if (event && event.status) {
        const status = event.status;

        const colorLookup = {
          Offen: "#696969",
          Abgesagt: "#BD3E43",
          Erledigt: "#3e8729",
          Temporär: "#1497a0",
          Ungültig: "#d47910",
        };

        if (event.color) {
          color = event.color;
        } else {
          color = colorLookup[status];
        }
      }

      return color;
    },

    getLighterEventColor(event) {
      let color = this.getEventColor(event);
      let rgb = this.hexToRgb(color);
      let blend = this.blendWhite(rgb, 0.4);
      return this.rgbToHex(blend.r, blend.g, blend.b);
    },

    eventEditDisabled(event) {
      let start = moment(event.start).utc(true);
      let now = moment().utc(true);

      return now.isAfter(start);
    },

    getEventBorderClasses(event) {
      if (event.termintyp != "Behandlung") {
        return {
          "summaryEntry": true,
        };
      }

      let classes = {
        offen: event.status == "Offen",
        abgesagt: event.status == "Abgesagt",
        erledigt: event.status == "Erledigt",
        temp: event.status == "Temporär",
        ungueltig: event.status == "Ungültig",
      };

      return classes;
    },

    getEventBorderStyle(event) {
      if (event.termintyp == "Intern") {
        return { color: event.color };
      }
    },

    isHoliday(date) {
      return this.$store.getters["holidays/isHoliday"](date);
    },

    getHoliday(date) {
      return this.$store.getters["holidays/getHolidayByDate"](date);
    },

    getCalendarInterval() {
      const interval_minutes = this.$store.getters["settings/getTimeSlotResolution"]
      const interval_count = 1440 / interval_minutes
      return [interval_minutes, interval_count]
    },


    getCurrentTimeHHmm() {
      return moment().format("HH:mm");
    },

    getUnavailableTimes(mitarbeiterName, calendarWeekday) {
      let index = calendarWeekday - 1;
      if (index == -1) {
        index = 6;
      }

      let mitarbeiter = this.$store.getters[
        "mitarbeiter/getMitarbeiterByCategory"
      ](mitarbeiterName);
      if (mitarbeiter) {
        let mitarbeiterId = mitarbeiter.id;
        let times = this.mitarbeiterUnavailableTimes[mitarbeiterId];
        return times ? times[index] : [];
      }
      return [];
    },

    getTopHeight(entry, timeToY) {
      let [start, end] = entry;

      let top = timeToY(start);
      let height = timeToY(end) - top;

      return { top: top + "px", height: height + "px" };
    },

    getTotalTopHeight(timeToY) {
      return this.getTopHeight(["00:00", "24:00"], timeToY);
    },

    nowY(timeToY) {
      if (this.cal) {
        if (timeToY(this.cal.times.now)) {
          return timeToY(this.cal.times.now) + "px";
        }
      }

      // case where cal not set or timeToY returns false
      return "-10px";
    },

    startDrag({ event, timed }, mousedown) {
      // only run for mousedown.left
      // start drag only if status is "Offen" (but a future appointment) and event is timed
      if (
        mousedown.button == 0 &&
        event &&
        timed &&
        event.status == "Offen" &&
        !this.moveIsActive &&
        !this.select_snackbar &&
        !this.repick_snackbar &&
        !this.dragEvent &&
        !this.createEvent &&
        !this.dragStarted
      ) {

        this.dragEvent = event;
        this.dragTime = null;
        this.createStart = moment(event.start).valueOf();
        this.extendOriginal = moment(event.end).valueOf();
        this.categoryOriginal = this.dragEvent.category;
        this.employeeOriginal = this.dragEvent.mitarbeiter;
        this.dragStarted = true;
        this.dragEnded = false;
      }
    },

    startTime(tms, mousedown) {
      if (!this.moveIsActive) {
        if (mousedown.button == 0) {
          const mouse = this.toTime(
            tms.year,
            tms.month,
            tms.day,
            tms.hour,
            tms.minute
          );

          if (this.dragEvent && this.dragTime === null) {
            const start = moment(this.dragEvent.start).valueOf();
            this.dragTime = mouse - start;
          }
        }
      }
    },

    extendBottom(event) {
      // assign data of currently extended event
      this.createEvent = event;
      this.createStart = moment(event.start).valueOf();
      this.extendOriginal = moment(event.end).valueOf();
      this.categoryOriginal = this.createEvent.category;
      this.employeeOriginal = this.createEvent.mitarbeiter;
      this.extendStarted = true;
      this.extendEnded = false;
    },

    mouseMove({ year, month, day, hour, minute, category }) {
      const mouse = this.toTime(year, month, day, hour, minute);
      if (this.calendarType != "category" || category || !this.moveIsActive) {
        if (this.dragEvent && this.dragTime) {
          // drag logic
          const start = moment(this.dragEvent.start).valueOf();
          const end = moment(this.dragEvent.end).valueOf();

          const duration = end - start;

          const newStartTime = mouse - this.dragTime;
          const newStart = this.roundTime(newStartTime);
          const newEnd = newStart + duration;

          this.dragEvent.start = moment(newStart)
            .utc(true)
            .toISOString()
            .slice(0, 19);

          this.dragEvent.end = moment(newEnd)
            .utc(true)
            .toISOString()
            .slice(0, 19);

          if (category && this.dragEvent.category != category) {
            this.dragEvent.category = category;
          }
        } else if (this.createEvent && this.createStart !== null) {
          // bottom expand logic
          const mouseRounded = this.roundTime(mouse, false);

          const min = Math.min(mouseRounded, this.createStart);
          const max = Math.max(mouseRounded, this.createStart);

          this.createEvent.start = moment(min)
            .utc(true)
            .toISOString()
            .slice(0, 19);
          this.createEvent.end = moment(max)
            .utc(true)
            .toISOString()
            .slice(0, 19);
        }
      }
    },

    async endDrag() {
      if (!this.createEvent && !this.dragEvent) {
        return;
      }

      this.dragEnded = true;
      this.extendEnded = true;

      let valid = false;
      let type = null;
      let termin = null;
      let errors = [];

      let preValidationStart = null;
      let preValidationEnd = null;
      let preValidationEmployee = null;

      // The drag/extend may have an delay, so we need to wait for a few ms to make sure the drag/extend is finished
      // This ensures that we get the correct start/end times, otherwise might run the validation with the wrong times and save invalid data
      await new Promise((resolve) => setTimeout(resolve, 100));
    

      if (this.createEvent) {
        type = "extend";
        termin = this.createEvent;
      }

      if (this.dragEvent) {
        if (this.dragEvent.category != this.categoryOriginal) {
          type = "drag-category";
        } else {
          type = "drag";
        }
        termin = this.dragEvent;
      }


      if (termin) {
        if (termin.status == "Offen") {
          const org_start = moment(this.createStart);
          const org_end = moment(this.extendOriginal);
          const new_start = moment(termin.start);
          const new_end = moment(termin.end);

          const dates_changed =
            !org_start.isSame(new_start) || !org_end.isSame(new_end);

          const category_changed = termin.category != this.categoryOriginal;

          // Only run checks if dates/category changed
          if (dates_changed || category_changed) {
            valid = true;

            // Update employee if category changed - fetch Id from category
            if (category_changed) {
              termin.mitarbeiter = this.$store.getters["mitarbeiter/getMitarbeiterByCategory"](termin.category).id;
            }

            preValidationStart = termin.start;
            preValidationEnd = termin.end;
            preValidationEmployee = termin.mitarbeiter;
            const validation = await this.validateAppointment(termin);

            if (!validation.isValid) {
              errors = errors.concat(validation.errors[termin.id]);
            }
          }
        }
      }

      if (valid) {

        termin.start = preValidationStart;
        termin.end = preValidationEnd;
        termin.mitarbeiter = preValidationEmployee;

        this.openAndLoadDragConfirmation({
          type: type,
          termin: termin,
          original_start: this.createStart,
          original_end: this.extendOriginal,
          original_category: this.categoryOriginal,
          errors: errors,
        });

        setTimeout(() => {
          this.extendStarted = false;
          this.dragStarted = false;
        }, 200);
      } else {
        this.extendStarted = false;
        this.dragStarted = false;
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.categoryOriginal = null;
      this.employeeOriginal = null;
    },

    cancelDrag() {
      if (this.$store.getters["overlays/dragConfirmationActive"]) {
        return
      }

      if (this.dragStarted && !this.dragEnded && this.dragEvent) {
        this.dragEvent.start = moment(this.createStart).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        this.dragEvent.end = moment(this.extendOriginal).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        this.dragEvent.category = this.categoryOriginal;
        this.dragEvent.mitarbeiter = this.employeeOriginal;
      } else if (this.extendStarted && !this.extendEnded && this.createEvent) {
        this.createEvent.start = moment(this.createStart).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        this.createEvent.end = moment(this.extendOriginal).format(
          "YYYY-MM-DDTHH:mm:ss"
        );
        this.createEvent.category = this.categoryOriginal;
        this.createEvent.mitarbeiter = this.employeeOriginal;
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
      this.extendOriginal = null;
      this.categoryOriginal = null;
      this.employeeOriginal = null;
      this.dragStarted = false;
      this.dragEnded = false;
    },

    roundTime(time, down = true) {
      const roundTo = 5; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },

    toTime(year, month, day, hour, minute) {
      return new Date(year, month - 1, day, hour, minute).getTime();
    },

    closeTerminPreview() {
      this.calendar.selectedOpen = false;
      this.calendar.selectedEvent = {};
    },

    editAppointment() {
      let gruppen_id = this.calendar.selectedEvent.gruppen_id;
      this.openAndLoadAppointmentEdit(gruppen_id);
    },

    moveTermin() {
      let data = {
        componentName: null,
        termin: this.calendar.selectedEvent,
      };
      // Close the selected event
      this.calendar.selectedOpen = false;

      this.switchToTermin(this.calendar.selectedEvent);
      this.openTerminMove(data);
    },

    openDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    openInvalidAppointmentsDialog() {
      this.invalidAppointmentsDialog = true;
    },

    closeInvalidAppointmentsDialog() {
      this.invalidAppointmentsDialog = false;
    },

    closeDialogAndOpenNacherfassung() {
      this.invalidAppointmentsDialog = false;
      const data = {
        "status": "Ungültig",
      }
      this.openNacherfassung(data);
    },

    async deleteTermin() {
      this.deleteDialog = false;

      let response_data = await this.deleteAppointment(this.calendar.selectedEvent.id);

      // Close the selected event
      this.calendar.selectedOpen = false;
      this.calendar.selectedEvent = {};

      this.openExcessPaymentNotification(response_data.warnings);
    },

    async createTerminZettel() {
      let data = {
        gruppen_id: this.calendar.selectedEvent.gruppen_id,
      };

      await this.getTerminZettel(data);
    },

    async confirmTermin() {
      this.isLoading = true;
      this.calendar.selectedOpen = false;

      let data = {
        id: this.calendar.selectedEvent.id,
        status: "Erledigt",
      };

      await this.updateTerminStatus(data);
      this.isLoading = false;
    },

    async rejectTermin() {
      this.calendar.selectedOpen = false;
      this.setTerminRejectId(this.calendar.selectedEvent.id);
      this.openTerminReject();
      this.calendar.selectedEvent = {};
    },

    async openSettings() {
      await this.openAndLoadSettings();
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    getNearestTime(hour, minute) {
      // 5 minute intervals
      let min_gap = 60;
      let new_minute = null;
      let new_hour = hour;

      for (var i = 0; i < 13; i++) {
        var diff = Math.abs(minute - i * 5);
        if (diff < min_gap) {
          min_gap = diff;
          new_minute = i * 5;
        } else {
          break;
        }
      }

      // hour change
      if (new_minute == 60) {
        new_hour += 1;
        new_minute = 0;
      }

      let time_str = "";
      if (new_hour < 10) {
        time_str += "0";
      }

      time_str += new_hour.toString() + ":";

      if (new_minute < 10) [(time_str += "0")];

      time_str += new_minute.toString();

      return [new_hour, new_minute, time_str];
    },

    async addTerminFromClick(tms) {
      let [h, m, time_str] = this.getNearestTime(tms.hour, tms.minute);

      // Possible cases
      // 1. Click on calendar after AddTermin/EditTermin window was openend / is active
      // 2. Click on calendar when moveTermin window was openend

      let data = null;
      let duration = null;
      let repickId = null;

      if (this.addIsActive || this.editIsActive) {
        // Duration will always be equal to the duration of the selected treatments
        duration = this.$store.getters["overlays/getBehandlungenDuration"];
        repickId = this.$store.getters["overlays/getRepickId"];

      } else if (this.moveIsActive) {
        // Duration will be the difference between the start and end time of the selected termin (in minutes)
        // There is no guarantee that the duration will be equal to the duration of the selected treatments
        duration = this.$store.getters["overlays/getTerminMoveDuration"];
        repickId = this.$store.getters["overlays/getTerminMoveId"];

      } else {
        // Ignore click if no relevant overlay is active
        return;
      }

      let mitarbeiter = null;
      let available = null;

      // CATEGORY click e.g. an employee was selected / is available
      if (this.calendarType == "category" && tms.category) {
        mitarbeiter = this.$store.getters[
          "mitarbeiter/getMitarbeiterByCategory"
        ](tms.category);


        let params = {
          date: tms.date,
          hour: h,
          minute: m,
          duration: duration,
          mitarbeiterId: mitarbeiter.id,
          repickId: repickId,
        }

        available = await this.isAppointmentAvailable(params);
      }

      // DAY click e.g. employee was not selected / no employee information available
      if (this.calendarType != "category") {
        if (this.moveIsActive) {
          mitarbeiter = this.$store.getters["mitarbeiter/getMitarbeiterbyId"](
            this.$store.state.overlays.termin_move.termin.current.mitarbeiter
          );
        }

        const mitarbeiterId = mitarbeiter ? mitarbeiter.id : null;

        let params = {
          date: tms.date,
          hour: h,
          minute: m,
          duration: duration,
          mitarbeiterId: mitarbeiterId,
          repickId: repickId,
        }

        available = await this.isAppointmentAvailable(params);

        // If only one mitarbeiter is available, select it automatically
        if (available.available_mitarbeiter.size == 1) {
          let firstMitarbeiterId = [...available.available_mitarbeiter][0];
          mitarbeiter = this.$store.getters[
            "mitarbeiter/getMitarbeiterbyId"
          ](firstMitarbeiterId);
        }
      }

      // Check if the proposed appointment is valid / can be created/modified in the current state

      if(!available){
        // This is needed as there is a click event for time + timecategory
        return
      }
      
      if(available.valid){
        data = {
          mitarbeiter: mitarbeiter,
          start_str: time_str,
          hour: h,
          minute: m,
          date: tms.date,
          status: "Temporär", // TODO: FIX
          valid: true,
          available_mitarbeiter: available.available_mitarbeiter,
        };
      } else {
        this.busy_message = available.message;
        this.mitarbeiter_busy_snackbar = true;
      }
    
      // Appointment is only relevant if data was set
      if (data) {
        if (this.addIsActive || this.editIsActive) {
          // Update existing Termin-Overlay (AddTermin/EditTermin)
          if (repickId) {
            // Replace existing entry
            this.replaceTerminInList(data);
          } else {
            // Nothing to replace, add new entry
            this.addTerminToList(data);
          }
        } else if (this.moveIsActive) {
          // Move Appointment
          this.handleMovedTermin(data);
        }
      }
    },

    setToday() {
      var now = new Date();
      const offset = now.getTimezoneOffset();
      now = new Date(now.getTime() - offset * 60 * 1000);
      this.calendar.now = now.toISOString().split("T")[0];
    },

    switchToTermin(termin) {
      let date_str = "";
      let time = 0;

      if ("start" in termin) {
        var datetime = moment(termin.start).utc(true);
        date_str = datetime.format("YYYY-MM-DD");
        time = datetime.hour() * 60 + datetime.minute();
      } else {
        date_str = termin.startDate ? termin.startDate : termin.start_date.format("YYYY-MM-DD");
        const time_str = termin.startTime ? termin.startTime : termin.start_time;
        time =
          time_str.substring(0, 2) * 60 +
          parseInt(time_str.substring(3, 5), 10);
          
      }

      this.calendar.now = date_str;
      this.scrollToTime(time);
    },

    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },

    scrollToTime(time_info = null) {
      const time = time_info ? time_info : this.getCurrentTime();
      if (this.cal.type == "category") {
        const y = this.cal.timeToY(time);
        if (y === false || !this.cal.$el) {
          // pass
        }
        const offset = 50;
        this.cal.$el.scrollTop = y - offset;
      } else {
        const first = Math.max(0, time - (time % 30) - 30);
        this.cal.scrollToTime(first);
      }
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    clearMitarbeiter() {
      this.calendarCategories = [];
    },

    viewDay({ date }) {
      this.calendar.now = date;
      this.calendarType = "day";
    },

    // Timer related methods
    async logout() {
      await this.LogOut();
      this.$router.push("/login");
    },

    async updateTimer() {
      this.isLoading = true;
      await this.RefreshToken();
      this.runTimer();
      this.isLoading = false;
    },

    async updateTimerAndData() {
      this.isLoading = true;

      // Get new token and reset timer
      await this.RefreshToken();
      this.runTimer();

      // Clear and get new application data
      this.ClearApplicationData();
      await this.GetApplicationData();

      this.isLoading = false;
    },

    runTimer() {
      // Set a new interval
      this.timerInterval = setInterval(() => {
        let now = moment();
        let diff = now.diff(this.timerStart, "seconds");

        // logout after 1 hour
        if (diff >= 3600) {
          this.logout();
          return;
        }

        // show timeout popup after 50 minutes
        if (diff >= 3000 && !this.timeoutShown) {
          this.timeoutShown = true;
          this.openTimeout();
        } else if (diff < 3000) {
          this.timeoutShown = false;
        }

        const new_value = 3600 - diff;
        if (new_value != this.timerValue) {
          this.timerValue = new_value;
        }
      }, 500);
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.calendar.selected_x = nativeEvent.clientX;
        this.calendar.selected_y = nativeEvent.clientY;

        this.calendar.selectedEvent = event;

        setTimeout(() => {
          if (this.calendar.selectedEvent && !this.dragEvent && !this.createEvent) {
            this.calendar.selectedOpen = true;
          }
        }, 100);
      };

      if (
        !this.moveIsActive &&
        !this.select_snackbar &&
        !this.repick_snackbar 
      ) {
        if (this.calendar.selectedOpen) {
          this.calendar.selectedOpen = false;
          setTimeout(open, 20);
        } else if (!this.extendStarted || this.dragStarted && this.dragEnded) {
          open();
        }
      }
      nativeEvent.stopPropagation();
    },

    handleMouseUp(event) {
      const target = event.target
      const targetName = target.className

      if (this.dragEvent || this.createEvent) {

        const forbiddenLocalNames = [
          "html",
          "button",
        ]

        if (forbiddenLocalNames.includes(target.localName)){
          this.cancelDrag()
        }

        // ! Hack :)
        /*
        const forbiddenTargets = [
          "v-calendar-daily",
          "v-calendar-category",
          "v-btn",
          "v-calendar v-calendar-daily v-calendar-category theme--light v-calendar-events",
          "summaryEntry",
          "event-border summaryEntry"
        ]
          */

        if (targetName == ""){
          return
        }

        const validTargets = [
          "v-calendar-category__column",
          "v-calendar-daily__day-interval",
          "v-event-draggable pl-3 event-summary",
          "v-event-drag-bottom",
          "primary-nav-btn v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary",
          "summaryEntry",
          "event-border offen",
          "event-border abgesagt",
          "event-border ungueltig",
          "event-border erledigt",
          "event-border temp",
        ]

        // instead of includes we use startsWith to avoid issues with vuetify classes

        if (validTargets.find((target) => targetName === target)) {
          return
        } else {
          this.cancelDrag()
        }

      }
    }

  },

  async beforeDetroy() {
    window.removeEventListener('mouseup', this.handleMouseUp)
  },  


  async beforeMount() {
    await this.RefreshToken();
    this.runTimer();

    this.isLoading = true;
    await this.ClearApplicationData();
    await this.GetApplicationData();

    const [interval_minutes, interval_count] = this.getCalendarInterval()

    this.calendar.interval_minutes = interval_minutes;
    this.calendar.interval_count = interval_count;
    this.calendar.interval_height = this.$store.getters["settings/getTimeSlotHeight"];

    this.isLoading = false;
    setTimeout(() => {

            this.scrollToTime();
            this.updateTime();
          });
    // Check for invalid appointments and show dialog if necessary
    let invalidData = await this.checkInvalidAppointments();
    if (invalidData.invalidExist) {
      this.invalidAppointmentsCount = invalidData.totalEntries;
      this.openInvalidAppointmentsDialog();
    }
  },
};
</script>

<style>
.theme--light.v-calendar-daily {
  border-left: 0px !important;
  border-top: 0px !important;
}

.v-select.v-select--chips.v-input--dense .v-select__selections {
  min-height: 36px !important;
}

.v-calendar-daily__interval-text {
  font-size: 14px !important;
  top: -9px !important;
}

.v-current-time {
  height: 2px;
  background-color: #ae2855;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
  z-index: 1;
}

.v-current-time-chip {
  background-color: rgb(174, 40, 85) !important;
  left: -25px;
  top: -12px;
  border-color: #000000 !important;
  color: #ffffff !important;
  border-width: thin;
  border-style: solid;
}

.v-closed-hours {
  background-color: #eaeaea99;
  position: absolute;
  z-index: 0;
  right: 0;
  left: -1px;
}

.v-closed-hours-text {
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 5px;
}

.v-unavailable-hours {
  background-color: #c9000013;
  position: absolute;
  z-index: 0;
  right: 0;
  left: -1px;
}

.v-unavailable-hours-text {
  font-weight: 500;
  font-size: 14px;
  padding-right: 10px;
  padding-top: 5px;
  text-align: right;
}

.v-holidays {
  background-color: #6947b456;
  position: absolute;
  z-index: 0;
  right: 0;
  left: -1px;
}

.v-holidays-text {
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 5px;
}

.event-menu {
  margin-left: 20px !important;
  margin-top: -20px !important;
}

.entry-icon {
  top: -2px;
}

.event-entry {
  color: black;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.event-subentry {
  font-size: 1em;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.v-snack__content {
  font-size: 1rem !important;
}

.v-application .pl-1 {
  padding-left: 10px !important;
}

.theme--light.v-calendar-events .v-event-more {
  background-color: unset !important;
}

.v-calendar .v-event-timed {
  z-index: 1;
}

.v-calendar-daily__head {
  background: white;
}

.v-btn--floating {
  position: relative;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
}

.v-event-drag-bottom::after {
  display: none;
  position: absolute;
  left: 50%;
  height: 4px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 16px;
  margin-left: -8px;
  opacity: 0.8;
  content: "";
}

.v-event-drag-bottom:hover::after {
  display: block;
}

.v-application .transparent {
  background-color: unset;
  border-color: unset;
}

.v-calendar-category .v-calendar-daily_head-weekday {
  width: 56px !important;
}

.m_higher {
  bottom: 78px;
}

.higher {
  bottom: 60px;
}

.v-btn--icon.v-size--small {
  height: 38px;
  width: 38px;
}

.colorbox {
  height: 15px;
  width: 15px;
  margin-top: 5px;
  margin-right: 5px;
  border: 1px solid black;
}

.colorbox.offen {
  background-color: #696969;
}

.colorbox.abgesagt {
  background-color: #bd3e43;
}

.colorbox.ungueltig {
  background-color: #d47910;
}

.colorbox.erledigt {
  background-color: #3e8729;
}

.colorbox.feiertag {
  background-color: #6947b4;
}

.colorbox.temp {
  background-color: #1497a0;
}

.colorboxtext {
  margin-top: 2px;
  font-size: 14px;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.18) !important;
}

.event-border {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  left: 0;
  bottom: 0;
  top: 0;
  border-style: solid;
  border-width: 4px;
  content: "";
  position: absolute;
}

.event-border.offen {
  color: #696969;
}

.event-border.abgesagt {
  color: #bd3e43;
}

.event-border.ungueltig {
  color: #d47910;
}

.event-border.erledigt {
  color: #3e8729;
}

.event-border.temp {
  color: #1497a0;
}

.event-summary {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  white-space: nowrap;
}

.v-calendar .overlay-alert {
  font-size: 1.1rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
