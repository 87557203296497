<template>
  <v-row justify="center">
    <v-dialog v-model="active" persistent max-width="1200px">
      <v-card light>
        <v-card-title>
          <span>Einstellungen</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeWindow">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <template>
              <v-tabs v-model="settingsTab">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab
                  :disabled="item.disabled"
                  v-for="item in items"
                  :key="item.id"
                >
                  {{ item.text }}
                </v-tab>
              </v-tabs>
            </template>
          </v-container>

          <v-tabs-items v-model="settingsTab" v-if="active">
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="1">
                      <v-avatar
                        color="white"
                        size="100"
                        tile
                        class="elevation-3"
                      >
                        <img v-if="logoUrl" :src="logoUrl" />
                        <v-icon v-if="!logoUrl" dark>
                          mdi-account-circle
                        </v-icon>
                      </v-avatar>
                    </v-col>

                    <v-col cols="4" class="ml-10">
                      <v-file-input
                        class="pt-0"
                        v-model="logoInput"
                        accept="image/jpeg, image/png"
                        label="Bild auswählen"
                        prepend-icon="mdi-image"
                        :disabled="isUploading"
                        :loading="isUploading"
                        counter
                        :error-messages="logoErrors"
                        @change="setLogoSize"
                        @click:clear="clearLogoSize"
                      >
                        <template v-slot:counter>
                          <span
                            v-if="logoErrors.length == 0"
                            class="v-counter"
                            style="color: rgba(0, 0, 0, .6);"
                            >max. 500 x 500</span
                          >
                          <span v-else></span>
                        </template>
                      </v-file-input>
                      <v-btn
                        class="mt-3"
                        color="primary"
                        small
                        :disabled="
                          logoErrors.length > 0 ||
                            !logoInput ||
                            logoInput.length == 0 ||
                            isUploading
                        "
                        @click="uploadUserLogo"
                        :loading="isUploading"
                        >Logo ändern</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        v-model="timeSlotResolution"
                        :items="timeSlotResolutions"
                        label="Zeitintervall"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="timeSlotHeight"
                        :items="timeSlotHeights"
                        label="Zeitintervall-Höhe (px)"
                      ></v-select>
                    </v-col>
                  </v-row>
                  </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="8">
                      <v-autocomplete
                        ref="heilmittel"
                        label="Heilmittel"
                        v-model="heilmittel"
                        :items="heilmittelList"
                        placeholder="Heilmittel auswählen"
                        prepend-icon="mdi-hand-heart"
                        :item-text="heilmittel_text"
                        item-value="id"
                        return-object
                        hide-no-data
                        style="border-radius: 0"
                      >
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="1" class="">
                      <v-btn
                        small
                        style="top: 20px"
                        outlined
                        @click="addHeilmittel"
                        :disabled="!heilmittel"
                      >
                        <v-icon left> mdi-plus</v-icon>
                        Hinzufügen
                      </v-btn>
                    </v-col>

                    <v-col cols="12" class="pt-0 pb-10">
                      <v-data-table
                        :headers="zeiteinheiten_headers"
                        :items="items.timeunits.selected"
                        :items-per-page="10"
                        class="elevation-1"
                        :footer-props="{
                          itemsPerPageText: 'Einträge pro Seite',
                          itemsPerPageAllText: 'Alle',
                        }"
                      >
                        <template #footer.page-text="props">
                          {{ props.pageStart }}-{{ props.pageStop }} von
                          {{ props.itemsLength }}
                        </template>

                        <template v-slot:item.zeiteinheit="props">
                          <v-edit-dialog
                            :ref="'time_dialog_' + props.item.keygesetzt"
                            :return-value.sync="props.item.zeiteinheit"
                            @close="setZeiteinheitenDirty"
                          >
                            {{ props.item.zeiteinheit }}
                            <template v-slot:input>
                              <v-text-field
                                v-model.number="props.item.zeiteinheit"
                                :rules="[is_number, is_inrange]"
                                label="Edit"
                                single-line
                                counter
                                v-mask="'###'"
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </template>

                        <template slot="no-data">
                          <div>
                            Es wurden noch keine eigenen Zeiteinheiten gesetzt!
                          </div>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="deleteZeiteinheit(item)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>Löschen</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <MitarbeiterArbeitszeiten />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeWindow">Zurück</v-btn>
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="saveSettings"
            :disabled="!isDirty"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="workingHoursErrorDialog.active"
      persistent
      max-width="750px"
    >
      <v-card light>
        <v-card-title style="background-color: #f5f5f5">
          <span>Aktualisierung des Terminstatus</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeWorkingHoursErrorDialog"
            :disabled="workingHoursErrorDialog.loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <div class="pb-5">
                  <span>Ihre Änderungen der Arbeitszeiten betreffen</span>
                  <span v-if="workingHoursErrorDialog.data.invalid == 1">
                    <b> 1 offenen Termin</b>, der mit dem Status
                    <span class="invalid">Ungültig</span> markiert wird.</span
                  >
                  <span v-else-if="workingHoursErrorDialog.data.invalid > 1">
                    <b> {{ workingHoursErrorDialog.data.invalid }}</b> offene
                    Termine, die mit dem Status
                    <span class="invalid">Ungültig</span> markiert werden.</span
                  >

                  <div v-if="workingHoursErrorDialog.data.open != 0">
                    <span v-if="workingHoursErrorDialog.data.open == 1"
                      >Zudem wird <b>1 zuvor ungültiger Termin</b> auf den
                      Status Offen gesetzt</span
                    >
                    <span v-if="workingHoursErrorDialog.data.open > 1"
                      >Zudem werden
                      <b
                        >{{ workingHoursErrorDialog.data.open }} zuvor ungültige
                        Termine</b
                      >
                      auf den Status
                      <span class="open">Offen</span> gesetzt.</span
                    >
                  </div>
                </div>

                <div class="pb-6">
                  <b>Hinweis:</b> Ungültige Termine müssen manuell angepasst und
                  auf einen gültigen Zeitpunkt verschoben werden.
                </div>
                <div style="font-weight: 600;">
                  Möchten Sie mit diesen Änderungen fortfahren?
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeWorkingHoursErrorDialog"
            :disabled="workingHoursErrorDialog.loading"
          >
            Zurück
          </v-btn>
          <v-btn
            class="primary-nav-btn"
            color="primary"
            text
            @click="saveWorkingHoursIgnoringConflicts"
            :disabled="workingHoursErrorDialog.loading"
            :loading="workingHoursErrorDialog.loading"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import MitarbeiterArbeitszeiten from "@/components/MitarbeiterArbeitszeiten.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { useVuelidate } from "@vuelidate/core";

const initialData = (self) => ({
  settingsTab: null,
  logoInput: [],
  logoSize: {
    width: 0,
    height: 0,
  },
  isUploading: false,
  items: {
    general: {
      id: 0,
      text: "Konto",
      disabled: false,
      dirty: false,
    },
    calendar: {
      id: 1,
      text: "Kalender",
      disabled: false,
      dirty: false,
    },
    timeunits: {
      id: 2,
      text: "Zeiteinheiten",
      disabled: false,
      selected: [],
      dirty: false,
    },
    worktimes: {
      id: 3,
      text: "Arbeitszeiten",
      disabled: false,
      dirty: false,
    },
  },

  zeiteinheiten_headers: [
    {
      text: "Bezeichnung",
      align: "start",
      value: "bezeichnung",
    },
    {
      text: "Abkürzung",
      value: "abkuerzung",
    },
    { text: "Dauer (min)", value: "zeiteinheit" },
    { text: "Aktionen", value: "actions", sortable: false },
  ],

  timeSlotResolution: null,
  timeSlotResolutions: [
    { text: "5 Minuten", value: 5 },
    { text: "10 Minuten", value: 10 },
    { text: "15 Minuten", value: 15 },
    { text: "20 Minuten", value: 20 },
    { text: "30 Minuten", value: 30 },
  ],

  timeSlotHeight: null,
  timeSlotHeights: [
    {text: "50px", value: 50},
    {text: "60px", value: 60},
    {text: "70px", value: 70},
    {text: "80px", value: 80},
    {text: "90px", value: 90},
    {text: "100px", value: 100},
    {text: "110px", value: 110},
    {text: "120px", value: 120},
    {text: "130px", value: 130},
    {text: "140px", value: 140},
    {text: "150px", value: 150},
  ],
  

  workingHoursErrorDialog: {
    active: false,
    data: {},
    loading: false,
  },

  weekdays: [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ],

  search: "",

  is_number: (v) => self.isInteger(v) || "Dauer muss eine Zahl sein!",
  is_inrange: (v) =>
    self.isInRange(v) || "Dauer muss zwischen 0 und 120 Minuten sein!",
});

function imageIsValidSize(value) {
  return value <= 500;
}

export default {
  name: "Settings",
  components: {
    MitarbeiterArbeitszeiten,
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations: {
    logoSize: {
      width: { imageIsValidSize },
      height: { imageIsValidSize },
    },
  },

  data() {
    var self = this;
    return initialData(self);
  },

  computed: {
    active() {
      return this.$store.getters["overlays/settingsActive"];
    },

    isDirty() {
      if (this.settingsTab == 3) {
        const isDirty = this.$store.getters["mitarbeiterarbeitszeiten/isDirty"];
        const hasErrors = this.$store.getters[
          "mitarbeiterarbeitszeiten/hasErrors"
        ];
        return isDirty && !hasErrors;
      }

      for (const prop in this.items) {
        if (this.items[prop].id == this.settingsTab) {
          return this.items[prop].dirty;
        }
      }
      return false;
    },

    logoErrors() {
      const errors = [];
      if (this.v$) {
        const entry = this.v$.logoSize;
        if (entry.width.$invalid || entry.height.$invalid) {
          errors.push("Logo darf nicht größer als 500x500 sein!");
        }
      }

      return errors;
    },

    logoUrl: {
      get() {
        return this.$store.getters["settings/getLogoUrl"];
      },
    },

    heilmittel: {
      get() {
        return this.$store.state.overlays.settings.timeunits.selected
          .heilmittel;
      },

      set(value) {
        this.$store.commit("overlays/updateSettingsHeilmittel", value, {
          root: true,
        });
      },
    },

    heilmittelList() {
      var selected_bezeichnungen = [];
      for (var heilmittel of this.items.timeunits.selected) {
        selected_bezeichnungen.push(heilmittel.bezeichnung);
      }
      return this.$store.getters["zeiteinheiten/getDefaultZeiteinheiten"](
        selected_bezeichnungen
      );
    },
  },

  methods: {
    ...mapActions("overlays", ["closeAndClearSettings"]),
    ...mapActions("zeiteinheiten", [
      "BulkOperationsKundenZeiteinheit",
      "GetTarifZeiteinheitenData",
    ]),
    ...mapActions("mitarbeiterarbeitszeiten", ["UpdateEmployeeWorkingHours"]),
    ...mapMutations("overlays", ["openAddRezept", "openAddPatient"]),
    ...mapMutations("mitarbeiterarbeitszeiten", ["resetTempArbeitszeiten"]),
    ...mapGetters("zeiteinheiten", ["getCustomZeiteinheiten"]),
    ...mapGetters("mitarbeiterarbeitszeiten", ["getArbeitszeiten"]),
    ...mapGetters("mitarbeiter", ["getMitarbeiterNamebyId"]),
    ...mapActions("settings", ["UploadLogo"]),
    ...mapActions("termine", ["getAppointmentData"]),

    async uploadUserLogo() {
      const file = this.logoInput;
      this.isUploading = true;
      await this.UploadLogo(file);
      this.logoInput = [];
      this.isUploading = false;
    },

    clearLogoSize() {
      this.logoSize.width = 0;
      this.logoSize.height = 0;
    },

    setLogoSize() {
      if (
        this.logoInput &&
        Object.getPrototypeOf(this.logoInput) == File.prototype
      ) {
        let img = new Image();
        img.onload = () => {
          this.logoSize.width = img.width;
          this.logoSize.height = img.height;
        };
        img.src = URL.createObjectURL(this.logoInput);
      }
    },

    getMitarbeiterName(id) {
      return this.$store.getters["mitarbeiter/getMitarbeiterNamebyId"](id);
    },

    allowedMinuteStep: (m) => m % 5 === 0,

    async setSelectedZeiteinheiten() {
      // First refetch all time units
      await this.GetTarifZeiteinheitenData();

      const customZeiteinheiten = this.getCustomZeiteinheiten();
      for (const ze of customZeiteinheiten) {
        this.items.timeunits.selected.push({ ...ze });
      }
    },

    setZeiteinheitenDirty() {
      this.items.timeunits.dirty = true;
    },

    isInteger: (str) => {
      var n = Math.floor(Number(str));
      return n !== Infinity && n === str;
    },

    isInRange: (str) => {
      var n = Math.floor(Number(str));
      return n !== Infinity && n === str && n >= 0 && n <= 120;
    },

    heilmittel_text: (item) => item.bezeichnung,

    addHeilmittel() {
      // create copy of heilmittel dont modify the one on the zeiteinheiten object
      this.items.timeunits.selected.push({ ...this.heilmittel });
      this.heilmittel = null;
    },

    closeWindow() {
      // reset data
      const data = initialData(this);
      Object.keys(data).forEach((k) => (this[k] = data[k]));

      this.resetTempArbeitszeiten();
      this.closeAndClearSettings();
    },

    openWorkingHoursErrorDialog(data) {
      this.workingHoursErrorDialog.active = true;
      this.workingHoursErrorDialog.data = data;
    },

    closeWorkingHoursErrorDialog() {
      this.workingHoursErrorDialog.active = false;
      this.workingHoursErrorDialog.data = {};
    },

    async saveWorkingHoursIgnoringConflicts() {
      this.workingHoursErrorDialog.loading = true;
      await this.UpdateEmployeeWorkingHours(true);
      await this.getAppointmentData();
      this.closeWorkingHoursErrorDialog();
      this.workingHoursErrorDialog.loading = false;
    },

    async saveSettings() {
      if (this.isDirty) {
        if (this.settingsTab == 0) {
          // TODO: implement
        } else if (this.settingsTab == 1) {
          const data = {
            time_slot_resolution: this.timeSlotResolution,
            time_slot_height: this.timeSlotHeight,
          };
          await this.$store.dispatch("settings/UpdateSettings", data);
          this.items.calendar.dirty = false;
        } else if (this.settingsTab == 2) {
          const selectedZeiteinheiten = this.items.timeunits.selected;
          await this.BulkOperationsKundenZeiteinheit(selectedZeiteinheiten);
          this.items.timeunits.dirty = false;
        } else if (this.settingsTab == 3) {
          let [response, status] = await this.UpdateEmployeeWorkingHours(false);

          if (status == 200) {
            await this.getAppointmentData();
          } else {
            this.openWorkingHoursErrorDialog(response);
          }
        }
      }
    },

    deleteZeiteinheit(item) {
      this.items.timeunits.selected = this.items.timeunits.selected.filter(
        (entry) => entry.bezeichnung !== item.bezeichnung
      );
      this.setZeiteinheitenDirty();
    },
  },
  watch: {
    active(val) {
      if (val) {
        this.setSelectedZeiteinheiten();
        this.timeSlotResolution = this.$store.getters[
          "settings/getTimeSlotResolution"
        ];

        this.timeSlotHeight = this.$store.getters["settings/getTimeSlotHeight"];
      }
    },

    timeSlotResolution: function(new_value, old_value) {
      if (old_value != null) {
        this.items.calendar.dirty = true;
      }
    },

    timeSlotHeight: function(new_value, old_value) {
      if (old_value != null) {
        this.items.calendar.dirty = true;
      }
    },

  },
};
</script>

<style>
.invalid {
  color: #d47910;
  font-weight: 500;
}

.open {
  color: #696969;
  font-weight: 500;
}
</style>
